import { Button, Modal, Segmented, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smokeHatchesModalsSelectors } from '@/core/redux/slices/modals/smokeExtraction/smokeHatches/selectors';
import { smokeHatchesModalsActions } from '@/core/redux/slices/modals/smokeExtraction/smokeHatches/slice';
import { productsActions } from '@/core/redux/slices/products/slice';
import NotFoundImage from '@/images/samples/not-found.svg';

import styles from './styles.module.scss';

const { Text, Paragraph } = Typography;

interface IModalContent {
  id: number;
  name: string;
  description: string;
  imageURL: string;
  manufactureID: number;
  manufactureName: string;
}

const ModalContent: React.FC<IModalContent> = ({
  id,
  name,
  description,
  imageURL,
  manufactureID,
  manufactureName
}) => {
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(smokeHatchesModalsActions.closeDisplayHatchTypeModal());
  };

  const handleSelect = () => {
    dispatch(
      productsActions.setSelectedProduct({
        id: id,
        name: name,
        description: description,
        image_url: imageURL,
        manufacturer_id: manufactureID,
      })
    );
    handleCancel();
  };
  const { isRender } = useAppSelector(
    smokeHatchesModalsSelectors.displayHatchTypeModal
  );

  const renderHeader = (
    <div>
      <img src={imageURL || NotFoundImage} alt={name} className='h-[172px] w-auto object-cover' />
      <div className='flex flex-col'>
        <Text type='secondary'>{manufactureName}</Text>
        <Text strong>{name}</Text>
      </div>
    </div>
  );

  const renderFooter = (
    <div className='flex justify-between'>
      <Button onClick={handleCancel}>Закрыть</Button>
      <Button type='primary' onClick={handleSelect}>
        Выбрать
      </Button>
    </div>
  );

  return (
    <Modal
      open={isRender}
      destroyOnClose
      onCancel={handleCancel}
      centered
      footer={renderFooter}
      title={renderHeader}
      className={styles.modal}
      width={572}
    >
      <div className='flex flex-col gap-2.5 items-start'>
        <Segmented<string>
          options={['Описание']}
          onChange={(value) => {
            console.log(value); // string
          }}
        />
        <Paragraph>{description}</Paragraph>
      </div>
    </Modal>
  );
};

export const DisplayHatchTypeModal: React.FC = () => {
  const { isRender, payload } = useAppSelector(
    smokeHatchesModalsSelectors.displayHatchTypeModal
  );

  if (isRender && payload) {

    const {id, name, image_url, description, manufacturer_id, manufacturer_name} = payload;
    return (
      <ModalContent
        id={id}
        name={name}
        imageURL={image_url}
        description={description}
        manufactureID={manufacturer_id}
        manufactureName={manufacturer_name}
      />
    );
  }

  return null;
};
