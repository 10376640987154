import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.calculationModals;

const addCalculationModal = createSelector(
  [selector],
  (state) => state.addCalculationModal
);

export const calculationModalsSelectors = {
  addCalculationModal,
};
