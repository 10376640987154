import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {  Card, Divider, Table, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { useAppDispatch } from '@/core/redux/hooks';
import { productsActions } from '@/core/redux/slices/products/slice';
import {
  ICell,
  ISmokeHatchesTable,
  systemCalculationsActions,
} from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import NotFoundImage from '@/images/samples/not-found.svg';


const { Text, Link } = Typography;

interface ITableData {
  key: string;
  rowName: string;
  [key: string]: ICell | string; // Dynamic columns
}

export const HatchTypeTable: React.FC<{
  defaultSelected: ICell;
  tableData: ISmokeHatchesTable;
  onCellSelect: (cellData: ICell) => void;
}> = ({ tableData, onCellSelect, defaultSelected }) => {
  const [selectedCell, setSelectedCell] = useState<ICell>();

  useEffect(() => {
    if (defaultSelected && !selectedCell) {
      setSelectedCell(defaultSelected);
    }
  }, [defaultSelected]);

  // Function to handle cell click
  const handleCellClick = (cellData: ICell) => {
    setSelectedCell(cellData);
    onCellSelect(cellData);
  };

  // Function to calculate color based on the "n" value
  const calculateColor = (
    n: number,
    maxValue: number,
    minValue: number
  ): string => {
    const green = [35, 120, 4]; // Green RGB
    const yellow = [173, 139, 0]; // Yellow RGB
    const red = [173, 33, 2]; // Red RGB

    // Normalize n value between 0 and 1
    const normalized = (n - minValue) / (maxValue - minValue);

    let color = '';
    if (normalized > 0.66) {
      // Higher range (red)
      const opacity = normalized; // Lower values get lower opacity
      color = `rgba(${red[0]}, ${red[1]}, ${red[2]}, ${opacity})`;
    } else if (normalized > 0.33) {
      // Mid range (yellow)
      const opacity = 1 - normalized;
      color = `rgba(${yellow[0]}, ${yellow[1]}, ${yellow[2]}, ${opacity})`;
    } else {
      // Lower range (green)
      const opacity = 1 - normalized;
      color = `rgba(${green[0]}, ${green[1]}, ${green[2]}, ${opacity})`;
    }
    return color;
  };

  // Get the min and max values of "n" in all cells
  const allNValues = tableData.rows.flatMap((row) =>
    row.cells.map((cell) => Number(cell.n))
  );
  const minValue = Math.min(...allNValues);
  const maxValue = Math.max(...allNValues);

  // Map table rows to the format required by Ant Design Table
  const dataSource: ITableData[] = tableData.rows.map((row, index) => {
    const rowData: ITableData = { key: String(index), rowName: row.rowName };
    tableData.columns.forEach((col, colIndex) => {
      rowData[col] = row.cells[colIndex];
    });
    return rowData;
  });

  // Define columns dynamically based on provided data
  const columns: ColumnsType<ITableData> = [
    {
      title: () => (
        <>
          <Text className='absolute left-2 bottom-2'>B, мм</Text>
          <div className='transform rotate-45 absolute top-2 left-8 bottom-2 right-4 w-[50px] flex items-center justify-center'>
            <Divider />
          </div>
          <Text className='absolute right-2 top-2'>A, мм</Text>
        </>
      ),
      dataIndex: 'rowName',
      key: 'rowName',
      fixed: 'left',
      width: 116,
    },
    ...tableData.columns.map((col) => ({
      title: col,
      dataIndex: col,
      key: col,
      render: (cell: ICell) => {
        const nValue = Number(cell.n);
        const isChecked =
          cell.A === selectedCell?.A && cell.B === selectedCell.B;
        return (
          <>
            {cell.n ? (
              <Tooltip>
                <Tag
                  color={
                    isChecked
                      ? 'default'
                      : calculateColor(nValue, maxValue, minValue)
                  }
                  className='w-full text-center cursor-pointer'
                  onClick={() => handleCellClick(cell)}
                >
                  {cell.n}
                </Tag>
              </Tooltip>
            ) : (
              <div className='flex justify-center items-center text-secondary'>
                –
              </div>
            )}
          </>
        );
      },
    })),
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ x: 'max-content' }}
    />
  );
};

export const TableView: React.FC<any> = ({
  selectedProduct,
  selectedManufacturer,
  selectedType,
  isSubmited,
  tableData,
}) => {
  const dispatch = useAppDispatch();

  const handleCellSelect = (selected: ICell) => {
    dispatch(systemCalculationsActions.setSelectedHatchType(selected));
  };

  // const handleOpenSelectSmokeHatchModal = () => {
  //   dispatch(smokeHatchesModalsActions.openSelectSmokeHatchModal(null));
  // };

  const handleChangeProduct = () => {
    dispatch(systemCalculationsActions.setSelectedHatchType(null));
    dispatch(systemCalculationsActions.setSmokeHatchesTable(null));
    dispatch(productsActions.setSelectedProduct(null));
  };

  const handleChangeHatchType = () => {
    dispatch(systemCalculationsActions.setSelectedHatchType(null));
    dispatch(systemCalculationsActions.setSmokeHatchesTable(null));
  };

  return (
    <Card
      className='bg-primary-light border-primary'
      classNames={{
        body: '!p-4',
      }}
    >
      <div className='flex justify-between'>
        <div>
          {selectedProduct && (
            <div className='flex gap-2'>
              <img
                src={selectedProduct?.image_url || NotFoundImage}
                className='w-[42px] h-[42px]'
              />
              <div className='flex gap-6'>
                <div className='flex flex-col'>
                  <Text type='secondary'>
                    {selectedManufacturer.name
                      ? selectedManufacturer.name
                      : '--'}
                  </Text>
                  <Text strong>
                    {selectedProduct ? selectedProduct.name : '--'}
                  </Text>
                </div>
                {selectedType?.A && (
                  <>
                    <div className='flex flex-col'>
                      <Text type='secondary'>Размер, мм</Text>
                      <Text strong>
                        {selectedType.A && selectedType.B ? (
                          <>
                            {selectedType.A} × {selectedType.B}
                          </>
                        ) : (
                          '--'
                        )}
                      </Text>
                    </div>
                    <div className='flex flex-col'>
                      <Text type='secondary'>Количество</Text>
                      <Text strong>
                        {selectedType.n ? selectedType.n + 'шт' : '--'}{' '}
                      </Text>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          <div className='flex gap-2 mt-2'>
            {/* <Link underline>Подробнее</Link> */}
            {isSubmited && (
              <Link underline onClick={handleChangeHatchType}>
                Изменить
              </Link>
            )}
            {!isSubmited && (
              <Link underline onClick={handleChangeProduct}>
                Изменить
              </Link>
            )}
          </div>
        </div>
      </div>
      {!isSubmited && tableData && !selectedType?.A && (
        <div className='mt-4'>
          <HatchTypeTable
            tableData={tableData}
            onCellSelect={handleCellSelect}
            defaultSelected={selectedType}
          />
        </div>
      )}
    </Card>
  );
};
