import { SuctionLeakageType } from '@/core/enums/suctionLeakageType';
import { TightnessClass } from '@/core/enums/tightnessClass';

export const resolverColumns = [
  {
    title: (
      <i>
        G<sub>нач.уч. кг/с</sub>
      </i>
    ),
    name: 'G_nach_uch',
    tooltip: 'Массовый расход перемещаемой среды в начале участка',
    editable: true,
    precision: 3,
  },
  {
    title: (
      <i>
        t<sub>нач.уч. °C</sub>
      </i>
    ),
    name: 't_nach_uch',
    tooltip: 'Температура перемещаемой среды в начале участка',
    editable: true,
    precision: 1,
  },
  // {
  //   title: (
  //     <i>
  //       T<sub>нач.уч. K</sub>
  //     </i>
  //   ),
  //   name: 'T_nach_uch',
  // },
  // {
  //   title: (
  //     <i>
  //       t<sub>1 °C</sub>
  //     </i>
  //   ),
  //   name: 't1',
  // },
  // {
  //   title: (
  //     <i>
  //       t<sub>2 °C</sub>
  //     </i>
  //   ),
  //   name: 't2',
  // },
  // {
  //   title: (
  //     <i>
  //       ρ<sub>1 кг/м3</sub>
  //     </i>
  //   ),
  //   name: 'rho1',
  // },
  // {
  //   title: (
  //     <i>
  //       ρ<sub>2 кг/м3</sub>
  //     </i>
  //   ),
  //   name: 'rho2',
  // },
  // {
  //   title: (
  //     <i>
  //       cp<sub>1 кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'cp1',
  // },
  // {
  //   title: (
  //     <i>
  //       cp<sub>2 кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'cp2',
  // },
  // {
  //   title: (
  //     <i>
  //       ν<sub>1 м2/с</sub>
  //     </i>
  //   ),
  //   name: 'nu1',
  // },
  // {
  //   title: (
  //     <i>
  //       ν<sub>2 м2/с</sub>
  //     </i>
  //   ),
  //   name: 'nu2',
  // },
  {
    title: (
      <i>
        ρ<sub>кг/м3</sub> 
      </i>
    ),
    tooltip: 'Плотность перемещаемой среды в начале участка',
    name: 'rho',
    precision: 3 
  },
  // {
  //   title: (
  //     <i>
  //       cp<sub>нач.уч. кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'Cp_na_uch',
  // },
  // {
  //   title: (
  //     <i>
  //       ν<sub>м2/с</sub>
  //     </i>
  //   ),
  //   name: 'nu',
  // },
  {
    title: (
      <i>
        K<sub>э мм</sub>
      </i>
    ),
    tooltip:
      'Абсолютная эквивалентная шероховатость поверхности воздуховода/канала',
    name: 'Ke',
    type: 'Ke',
    editable: true
  },
  {
    title: (
      <i>
        A<sub>мм</sub>
      </i>
    ),
    name: 'A',
    tooltip: 'Ширина или диаметр канала (воздуховода) на участке',
    editable: true,
  },
  {
    title: (
      <i>
        B<sub>мм</sub>
      </i>
    ),
    name: 'B',
    tooltip: 'Высота канала (воздуховода) на участке',
    editable: true,
  },
  {
    title: (
      <i>
        S
        <sub>
          м<sup>2</sup>
        </sub>
      </i>
    ),
    tooltip: 'Площадь сечения канала (воздуховода)',
    name: 'S',
    precision: 2
  },
  {
    title: (
      <i>
        D<sub>эк м</sub>
      </i>
    ),
    tooltip: 'Эквивалентный диаметр канала (воздуховода)',
    name: 'Dek',
    precision: 3
  },
  {
    title: (
      <i>
        v<sub>м/с</sub>
      </i>
    ),
    tooltip: 'Скорость среды',
    name: 'v',
    precision: 2
  },
  {
    title: (
      <i>
        P<sub>дин Па</sub>
      </i>
    ),
    tooltip: 'Динамическое давление',
    name: 'Pdin',
    precision: 1
  },
  // {
  //   title: (
  //     <i>
  //       R<sub>e</sub>
  //     </i>
  //   ),
  //   name: 'Re',
  // },
  // {
  //   title: <i>λ</i>,
  //   name: 'lmbda',
  // },
  {
    title: (
      <i>
        R<sub>Па/м</sub>
      </i>
    ),
    tooltip: 'Потери давления на трение',
    name: 'R',
    precision: 2
  },
  {
    title: (
      <i>
        l<sub>м</sub>
      </i>
    ),
    name: 'l',
    tooltip: 'Длина канала (воздуховода)',
    editable: true,
  },
  {
    title: (
      <i>
        R<sub>l Па</sub>
      </i>
    ),
    tooltip: 'Потери давления по длине канала (воздуховода)',
    name: 'Rl',
    precision: 1
  },
  {
    title: <i>∑ξ</i>,
    name: 'Xi_sum',
    tooltip: 'КМС на участке',
    editable: true,
  },
  {
    title: (
      <i>
        z<sub>Па</sub>
      </i>
    ),
    tooltip: 'Потери давления в местных сопротивлениях',
    name: 'z',
    precision: 1
  },
  {
    title: (
      <i>
        ∑P<sub>уч Па</sub>
      </i>
    ),
    tooltip: 'Суммарные потери давления на участке',
    name: 'P_uch_sum',
    precision: 1
  },
  {
    title: (
      <i>
        P<sub>кон.уч Па</sub>
      </i>
    ),
    tooltip: 'Давление в конце участка',
    name: 'P_kon_uch',
    precision: 1
  },
  {
    title: (
      <i>
        G<sub>доп кг/с</sub>
      </i>
    ),
    name: 'Gdop',
    tooltip: 'Массовый расход перемещаемой среды в ответвлении',
    editable: true,
  },
  {
    title: (
      <i>
        G<sub>нач.уч.+доп кг/с</sub>
      </i>
    ),
    tooltip: 'Суммарный расход участка и ответвления',
    name: 'Gsum',
    precision: 3
  },
  {
    title: <i>Учёт утечек и подсосов</i>,
    name: 'suction_leakage_type',
    type: 'enum',
    options: SuctionLeakageType,
    tooltip: 'Учет подсосов или утечек на участке',
    editable: true,
  },
  // {
  //   title: (
  //     <i>
  //       ΔР<sub>ут.под Па</sub>
  //     </i>
  //   ),
  //   name: 'delta_P',
  // },
  {
    title: (
      <i>
        A<sub>кл мм</sub>
      </i>
    ),
    name: 'Akl',
    tooltip: 'Ширина или диаметр закрытого клапана на участке',
    editable: true,
  },
  {
    title: (
      <i>
        B<sub>кл мм</sub>
      </i>
    ),
    name: 'Bkl',
    tooltip: 'Высота закрытого клапана на участке',
    editable: true,
  },
  {
    title: (
      <i>
        F<sub>кл м2</sub>
      </i>
    ),
    tooltip: 'Площадь закрытого клапана на участке',
    name: 'Fkl',
    precision: 2
  },
  {
    title: (
      <i>
        S
        <sub>
          d20 м<sup>3</sup>/кг
        </sub>
      </i>
    ),
    tooltip:
      'Удельная характеристика сопротивления дымогазопроницанию клапана при температуре 20 град. С',
    name: 'Sd20',
    editable: true,
  },
  {
    title: (
      <i>
        S
        <sub>
          d м<sup>3</sup>/кг
        </sub>
      </i>
    ),
    tooltip:
      'Удельная характеристика сопротивления дымогазопроницанию клапана при температуре перемещаемой среды',
    name: 'Sd',
    precision: 0
  },
  {
    title: (
      <i>
        ΔG<sub>кл кг/с</sub>
      </i>
    ),
    tooltip:
      'Массовый расход подсосов/утечек через закрытый противопожарный клапан',
    name: 'delta_Gkl',
    precision: 3
  },
  {
    title: <i>Класс герм. воздухов</i>,
    name: 'tightness_class',
    type: 'enum',
    options: TightnessClass,
    tooltip:
      'Класс герметичности канала (воздуховода) согласно Приложению М СП 60.13330.2020',
    editable: true,
  },
  {
    title: (
      <i>
        F<sub>возд м2</sub>
      </i>
    ),
    tooltip: 'Площадь поверхности воздуховода (канала)',
    name: 'Fvozd',
    precision: 2
  },
  {
    title: (
      <i>
        F<sub>фас. м2</sub>
      </i>
    ),
    name: 'Ffas',
    tooltip: 'Площадь поверхности фасонных изделий воздуховодов на участке',
    editable: true,
  },
  {
    title: (
      <i>
        F<sub>возд</sub> + F<sub>фас м2</sub>
      </i>
    ),
    tooltip:
      'Сумма площадей поверхности воздуховода (канала) и фасонных изделий',
    name: 'Fvozd_sum',
    precision: 2
  },
  // {
  //   title: (
  //     <i>
  //       ρ<sub>ут.под кг/м3</sub>
  //     </i>
  //   ),
  //   name: 'rho_utpod',
  // },
  // {
  //   title: (
  //     <i>
  //       A<sub>кл мм</sub>
  //     </i>
  //   ),
  //   name: 'A_tc',
  // },
  // {
  //   title: (
  //     <i>
  //       B<sub>кл мм</sub>
  //     </i>
  //   ),
  //   name: 'B_tc',
  // },
  // {
  //   title: (
  //     <i>
  //       C<sub>кл мм</sub>
  //     </i>
  //   ),
  //   name: 'C_tc',
  // },
  // {
  //   title: (
  //     <i>
  //       D<sub>кл мм</sub>
  //     </i>
  //   ),
  //   name: 'D_tc',
  // },
  {
    title: (
      <i>
        ΔG<sub>возд кг/с</sub>
      </i>
    ),
    tooltip:
      'Массовый расход подсосов/утечек через канал (воздуховод) и фасонные изделия',
    name: 'delta_Gvozd',
    precision: 3
  },
  {
    title: (
      <i>
        ∑ΔG<sub>ут.под кг/с</sub>
      </i>
    ),
    tooltip:
      'Сумма подсосов/утечек воздуха через поверхность каналов (воздуховодов) воздуховоды и клапаны',
    name: 'delta_Gpd_sum',
    precision: 3
  },
  {
    title: (
      <i>
        G<sub>кон.уч кг/с</sub>
      </i>
    ),
    tooltip: 'Массовый расход перемещаемой среды в конце участка',
    name: 'G_kon_uch',
    precision: 3
  },
  {
    title: (
      <i>
        ql<sub>кВт/м</sub>
      </i>
    ),
    name: 'ql',
    tooltip:
      'Потери тепла на единицу длины канала (при расчете систем вытяжной противодымной вентиляции)',
    editable: true,
  },
  {
    title: (
      <i>
        t<sub>подс</sub> t<sub>утеч °C</sub>
      </i>
    ),
    name: 't_pods',
    tooltip: 'Температура подсасываемого/утекающего воздуха',
    editable: true,
  },
  // {
  //   title: (
  //     <i>
  //       T<sub>подс К</sub>
  //     </i>
  //   ),
  //   name: 'T_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       t<sub>1 °C</sub>
  //     </i>
  //   ),
  //   name: 't1_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       t<sub>2 °C</sub>
  //     </i>
  //   ),
  //   name: 't2_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       сp<sub>1 кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'cp1_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       сp<sub>2 кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'cp2_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       ρ<sub>1 кг/м3</sub>
  //     </i>
  //   ),
  //   name: 'rho1_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       ρ<sub>2 кг/м3</sub>
  //     </i>
  //   ),
  //   name: 'rho2_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       ρ<sub>подс кг/м3</sub>
  //     </i>
  //   ),
  //   name: 'rho_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       сp<sub>подс кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'cp_pods',
  // },
  // {
  //   title: (
  //     <i>
  //       T<sub>кон.уч К</sub>
  //     </i>
  //   ),
  //   name: 'T_kon_uch',
  // },
  {
    title: (
      <i>
        t<sub>кон.уч °C</sub>
      </i>
    ),
    tooltip: 'Температура перемещаемой среды в конце участка',
    name: 't_kon_uch',
    precision: 1
  },
  // {
  //   title: (
  //     <i>
  //       cp<sub>кон.уч кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'cp_kon_uch',
  // },
  {
    title: (
      <i>
        ρ<sub>кон.уч кг/м3</sub>
      </i>
    ),
    tooltip: 'Плотность перемещаемой среды в конце участка',
    name: 'rho_kon_uch',
    precision: 3
  },
  // {
  //   title: (
  //     <i>
  //       t<sub>1 °C</sub>
  //     </i>
  //   ),
  //   name: 't1_kon_uch',
  // },
  // {
  //   title: (
  //     <i>
  //       t<sub>2 °C</sub>
  //     </i>
  //   ),
  //   name: 't2_kon_uch',
  // },
  // {
  //   title: (
  //     <i>
  //       сp<sub>1 кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'cp1_kon_uch',
  // },
  // {
  //   title: (
  //     <i>
  //       сp<sub>2 кДж/(кг°C)</sub>
  //     </i>
  //   ),
  //   name: 'cp2_kon_uch',
  // },
  // {
  //   title: (
  //     <i>
  //       ρ<sub>1 кг/м3</sub>
  //     </i>
  //   ),
  //   name: 'rho1_kon_uch',
  // },
  // {
  //   title: (
  //     <i>
  //       ρ<sub>2 кг/м3</sub>
  //     </i>
  //   ),
  //   name: 'rho2_kon_uch',
  // },
];
