import { useEffect, useRef, useState } from 'react';
import { Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { airExchangeSelectors } from '@/core/redux/slices/airExchange/selectors';
import { airExchangeActions } from '@/core/redux/slices/airExchange/slice';

import {
  AirExchangeFilter,
  IAirExchangeFilterForm,
} from './AirExchangeFIlter/AirExchangeFIlter';
import { AirExchangeList } from './AirExchangeList/AirExchangeList';

const { Text, Title } = Typography;

const defaultPageSize = 5;

const getMaxCurrentPage = (
  totalItems: number | null | undefined,
  itemsPerPage: number,
  currentPage: number
): number | undefined => {
  if (!totalItems) {
    return;
  }

  // Calculate the maximum possible page
  const maxPage = Math.ceil(totalItems / itemsPerPage);

  // If the current page exceeds the max page, return the max page
  if (currentPage > maxPage) {
    return maxPage;
  }

  // Otherwise, return the current page
  return currentPage;
};

export const AirExchangePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const airExchangeTotal = useAppSelector(
    airExchangeSelectors.airExchangeTotal
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showSize, setShowSize] = useState<number>(defaultPageSize);

  const [filterValues, setFilterValues] = useState<IAirExchangeFilterForm>();

  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const page =
      getMaxCurrentPage(airExchangeTotal, showSize, currentPage) || 1;

    dispatch(
      airExchangeActions.fetchAirExchangeList({
        search: filterValues?.['search'],
        status: filterValues?.['status'],
        page: page,
        size: showSize,
      })
    );

    listRef?.current?.scroll({ top: 0, behavior: 'smooth' });
  }, [currentPage, showSize]);

  useEffect(() => {
    setCurrentPage(1);

    dispatch(
      airExchangeActions.fetchAirExchangeList({
        search: filterValues?.['search'],
        status: filterValues?.['status'],
        page: 1,
        size: showSize,
      })
    );

    listRef?.current?.scroll({ top: 0, behavior: 'smooth' });
  }, [filterValues]);

  return (
    <div className='h-full overflow-hidden flex flex-col'>
      <div className='bg-white'>
        <div className='p-6 flex flex-col gap-2'>
          <Title level={3}>Справочник воздухообмена</Title>
          <AirExchangeFilter
            onFilterChange={(newValues) => setFilterValues(newValues)}
          />
          {airExchangeTotal !== null && airExchangeTotal !== undefined && (
            <Text type='secondary'>{airExchangeTotal} вариантов</Text>
          )}
        </div>
      </div>
      <div className='p-6 grow h-full overflow-auto' ref={listRef}>
        <div className='bg-white'>
          <div className='p-6'>
            <AirExchangeList
              currentPage={currentPage}
              onPaginationChange={(current, size) => {
                setCurrentPage(current);
                setShowSize(size);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
