import { FocusEvent, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Radio, SelectProps, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';
import { DebounceSelect } from '@/components/ui/DebounceSelect/DebounceSelect';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

const { Title } = Typography;

export interface IFireSafeZonesView {
  systemID: number;
}

export const FireSafeZonesView: React.FC<IFireSafeZonesView> = ({
  systemID,
}) => {
  const cityParams = useAppSelector(systemParamsSelectors.cityParams);
  const cityParamsLock = useAppSelector(systemParamsSelectors.cityParamsLock);

  const systemState = useAppSelector(systemSelectors.systemState);
  const dispatch = useAppDispatch();

  const methods = useForm<any>({
    defaultValues: {
      city_id: 1,
      city: 'Москва',
      ta: 0,
      pa: 0,
      hdr: 2,
      bdr: 0.9,
      Fdr: null,
      N: 1,
      vr: 1.5,
      Gr_opened: null,
      Lr_opened: null,
      tsf: 18,
      Tsf: null,
      psf: null,
      door_type: 'Противопожарная',
      SumFdsf: 2,
      Sdsf: null,
      SumFdl: 4,
      Sdl: null,
      Gsf_closed: null,
      Lsf_closed: null,
    },
  });

  const cityParamsOptions: SelectProps['options'] = useMemo(() => {
    if (!cityParams) {
      return [];
    }

    return cityParams.map((item) => ({
      value: item.id,
      label: item.city,
    }));
  }, [cityParams]);

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    dispatch(
      systemCalculationsActions.performFireProofZones({
        calc_id: systemID,
        data: data,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  const onSelectedCityChange = (id: string) => {
    if (!cityParams) {
      return;
    }

    const selectedCityID = Number(id);

    const selectedCity = cityParams.find((item) => item.id === selectedCityID);

    if (selectedCity) {
      methods.setValue('city', String(selectedCity.city));
      methods.setValue('ta', Number(selectedCity.cold_temp));
    }

    handlePerformCalculation();
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  const options = [
    { label: 'Противопожарная', value: 'Противопожарная' },
    { label: 'Дымогазонепроницаемая', value: 'Дымогазонепроницаемая' },
  ];

  useEffect(() => {
    if (!systemState) {
      dispatch(
        systemParamsActions.fetchCityParams({
          page: 1,
          size: 10,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (cityParamsLock === LoadingStatus.LOADED) {
      !systemState && cityParams && onSelectedCityChange('1');
    }
  }, [cityParams]);

  return (
    <FormProvider {...methods}>
      <form>
        <div className='py-4 px-2'>
          <Title level={5} className='mb-4'>
            Город
          </Title>
          <DebounceSelect
            size='large'
            showSearch
            placeholder='Выберите город'
            defaultValue={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            value={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            style={{ width: '100%' }}
            onChange={(newValue: any) => {
              methods.setValue('city_id', newValue.key);
              onSelectedCityChange(newValue.key);
            }}
            options={cityParamsOptions}
            fetchOptions={(search: string) => {
              dispatch(
                systemParamsActions.fetchCityParams({
                  page: 1,
                  size: 10,
                  search: search,
                })
              );
            }}
            loading={cityParamsLock === LoadingStatus.LOADING}
          />
        </div>

        <div className='py-4 px-2'>
          <div className='flex flex-col gap-8'>
            <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                name='ta'
                addonBefore={
                  <i>
                    t<sub>a</sub> =
                  </i>
                }
                addonAfter={'°C'}
                label='Температура наружного воздуха'
                disabled
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                name='pa'
                addonBefore={
                  <i>
                    ρ<sub>a</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    кг/м<sup>3</sup>
                  </i>
                }
                label='Плотность наружного воздуха'
                disabled
                onBlur={handleBlur}
                size='large'
              />
            </div>

            <div>
              <Title level={5}>Расчёт на открытую дверь</Title>

              <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
                <InputNumberForm
                  name='hdr'
                  addonBefore={
                    <i>
                      h<sub>dr</sub> =
                    </i>
                  }
                  addonAfter={<i>м</i>}
                  step='0.1'
                  label='Высота двери (створки) защищаемого помещения'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='bdr'
                  addonBefore={
                    <i>
                      b<sub>dr</sub> =
                    </i>
                  }
                  addonAfter={<i>м</i>}
                  step='0.1'
                  label='Ширина двери (створки) защищаемого помещения'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='Fdr'
                  addonBefore={
                    <i>
                      F<sub>dr</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>2</sup>
                    </i>
                  }
                  label='Площадь двери (створки) защищаемого помещения'
                  disabled
                  onBlur={handleBlur}
                  size='large'
                />
              </div>
            </div>

            <div>
              <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                <InputNumberForm
                  name='N'
                  addonBefore={<i>N =</i>}
                  label='Количество одновременно открытых дверей (створок)'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='vr'
                  addonBefore={
                    <i>
                      v<sub>r</sub> =
                    </i>
                  }
                  step='0.05'
                  addonAfter={<i>м/с</i>}
                  label='Минимально допустимая скорость истечения воздуха через одну открытую дверь (створку)'
                  note='Не менее 1,5 м/с'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='Gr_opened'
                  addonBefore={
                    <i>
                      G<sub>r</sub> =
                    </i>
                  }
                  addonAfter={<i>кг/с</i>}
                  label='Массовый расход воздуха, подаваемый в зону безопасности из расчета N открытых дверей (створок)'
                  onBlur={handleBlur}
                  fixValue={2}
                  disabled
                  size='large'
                />
                <InputNumberForm
                  name='Lr_opened'
                  addonBefore={
                    <i>
                      L<sub>r</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>3</sup>/ч
                    </i>
                  }
                  label='Объемный расход воздуха, подаваемый в зону безопасности из расчета N открытых дверей (створок)'
                  onBlur={handleBlur}
                  disabled
                  size='large'
                />
              </div>
            </div>

            <div>
              <Title level={5}>Расчёт на закрытую дверь</Title>

              <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                <InputNumberForm
                  name='tsf'
                  addonBefore={
                    <i>
                      t<sub>sf</sub> =
                    </i>
                  }
                  addonAfter={'°C'}
                  label='Температура подогреваемого воздуха, подаваемого в пожаробезопасную зону'
                  onBlur={handleBlur}
                  size='large'
                />
              </div>

              <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                <InputNumberForm
                  name='Tsf'
                  addonBefore={
                    <i>
                      T<sub>sf</sub> =
                    </i>
                  }
                  addonAfter={'°К'}
                     label='Температура подогреваемого воздуха, подаваемого в пожаробезопасную зону'
                  disabled
                  onBlur={handleBlur}
                  size='large'
                />

                <InputNumberForm
                  name='psf'
                  addonBefore={
                    <i>
                      p<sub>sf</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      кг/м<sup>3</sup>
                    </i>
                  }
                  disabled
                  onBlur={handleBlur}
                  label='Плотность подогреваемого воздуха, подаваемого в пожаробезопасную зону'
                  size='large'
                />
              </div>
              <div className='py-4'>
                <RadioGroupForm
                  name='door_type'
                  defaultValue='Противопожарная'
                  buttonStyle='outline'
                  label='Тип двери'
                  options={options}
                  optionType='button'
                  onChange={handlePerformCalculation}
                />
              </div>

              <div className='grid grid-cols-2 gap-4 gap-y-8 py-4 items-end'>
                <InputNumberForm
                  name='SumFdsf'
                  addonBefore={
                    <i>
                      ∑F<sub>dsf</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>2</sup>
                    </i>
                  }
                  label={`Суммарная площадь дверных проемов пожаробезопасной зоны`}
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='SumFdl'
                  addonBefore={
                    <i>
                      ∑F<sub>dl</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>2</sup>
                    </i>
                  }
                  label={`Суммарная площадь дверей лифтовых шахт, \nвыходящих в пожаробезопасную зону`}
                  note='Учитывается только двери шахт лифтов, без подпора воздуха'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='Sdsf'
                  addonBefore={
                    <i>
                      S<sub>dsf</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>3</sup>/кг
                    </i>
                  }
                  disabled
                  label='Удельное сопротивление воздухопроницанию закрытых дверей пожаробезопасной зоны'
                  onBlur={handleBlur}
                  fixValue={0}
                  size='large'
                />
                <InputNumberForm
                  name='Sdl'
                  addonBefore={
                    <i>
                      S<sub>dl</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>3</sup>/кг
                    </i>
                  }
                  disabled
                  fixValue={0}
                  label='Удельное сопротивление воздухопроницанию закрытых дверей лифтовых шахт, выходящих в пожаробезопасную зону'
                  onBlur={handleBlur}
                  size='large'
                />
                <InputNumberForm
                  name='Gsf_closed'
                  addonBefore={
                    <i>
                      G<sub>sf (закр)</sub> =
                    </i>
                  }
                  addonAfter={<i>кг/с</i>}
                  disabled
                  label='Массовый расход воздуха, подаваемый в пожаробезопасную зону из расчета утечек через неплотности дверных проемов'
                  onBlur={handleBlur}
                  fixValue={2}
                  size='large'
                />
                <InputNumberForm
                  name='Lsf_closed'
                  addonBefore={
                    <i>
                      L<sub>sf (закр)</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>3</sup>/ч
                    </i>
                  }
                  disabled
                  label='Объемный расход воздуха, подаваемый в пожаробезопасную зону из расчета утечек через неплотности дверных проемов'
                  onBlur={handleBlur}
                  size='large'
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
