import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICalculationModalPayload {
  building_object_id: number;
  calc_name?: string;
  object_name?: string;
  object_address?: string;
}

export interface IModal {
  isRender: boolean;
  payload: ICalculationModalPayload | null;
}

export interface ICalculationModalsState {
  addCalculationModal: IModal;
}

const initialState: ICalculationModalsState = {
  addCalculationModal: {
    isRender: false,
    payload: null,
  },
};

export const calculationModalsSlice = createSlice({
  name: 'calculationModals',
  initialState,
  reducers: {
    openAddCalculationtModal: (
      state,
      action: PayloadAction<ICalculationModalPayload | null>
    ) => ({
      ...state,
      addCalculationModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAddCalculationModal: (state) => ({
      ...state,
      addCalculationModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const calculationModalsReducer = calculationModalsSlice.reducer;
export const calculationModalsActions = calculationModalsSlice.actions;
