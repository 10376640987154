import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.products;

// manufactures

const manufacturers = createSelector([selector], (state) => state.manufactures);
const manufacturersLock = createSelector(
  [selector],
  (state) => state.manufacturesLock
);
// products

const prdoucts = createSelector([selector], (state) => state.products);
const prdouctsLock = createSelector([selector], (state) => state.productsLock);

// selected

const selectedManufacture = createSelector(
  [selector],
  (state) => state.selectedManufacture
);
const selectedProduct = createSelector(
  [selector],
  (state) => state.selectedProduct
);

export const productsSelectors = {
  prdoucts,
  prdouctsLock,
  manufacturers,
  manufacturersLock,
  selectedManufacture,
  selectedProduct,
};
