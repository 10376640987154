import { Navigate, Route, Routes } from 'react-router-dom';

import SystemAddRoute from '../calculation/SystemAddRoute';
import SystemRoute from '../calculation/SystemRoute';
import SystemViewRoute from '../calculation/SystemViewRoute';

import CalculationRoute from './CalculationRoute';
import ObjectsRoute from './ObjectsRoute';

const ObjectsRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route path='/:id' element={<CalculationRoute />} />
      <Route path='/:id/:calculationId' element={<SystemRoute />} />
      <Route path='/:id/:calculationId/system' element={<SystemAddRoute />} />
      <Route path='/:id/:calculationId/system/:systemId' element={<SystemViewRoute />} />
      <Route path='/' element={<ObjectsRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default ObjectsRootRoute;
