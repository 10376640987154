import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.system.calculations;

// hatch table

const smokeHatchesTable = createSelector(
  [selector],
  (state) => state.smokeHatchesTable
);

const selectedHatchType = createSelector(
  [selector],
  (state) => state.selectedHatchType
);

export const systemCalculationSelectors = {
  smokeHatchesTable,
  selectedHatchType,
};
