import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { authActions } from '@/core/redux/slices/auth/slice';
import { usersSelectors } from '@/core/redux/slices/users/selectors';

const schema = yup.object().shape({
  code: yup.string().required('Поле обязательно для заполнения'),
});

interface IRegisterConfirmForm {
  code: string;
}

export const RegisterConfirmPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(usersSelectors.user);

  const [isDisabled, setIsDisabled] = useState(false);
  const [counter, setCounter] = useState(60);

  const registerConfirmLock = useAppSelector(authSelectors.registerConfirmLock);

  const methods = useForm<IRegisterConfirmForm>({
    resolver: yupResolver(schema),
  });
  const { Text, Title } = Typography;

  const onSubmit = (data: IRegisterConfirmForm) => {
    if (!user) {
      return;
    }

    dispatch(
      authActions.registerConfirm({
        email: user.email,
        code: data.code,
      })
    );
  };

  const handleNewCodeRequest = () => {
    if (!user || !user.password) {
      return;
    }

    dispatch(
      authActions.register({
        email: user.email,
        password: user.password,
      })
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    // Decrement the counter if it is greater then 0
    if (isDisabled && counter > 0) {
      timer = setTimeout(() => {
        setCounter((prev) => prev - 1); // Decrement the counter by 1 after 1000ms (1 second)
      }, 1000);
    }
    // If the counter reaches 0, reset isDisabled to false and counter to 60
    else if (counter === 0) {
      setIsDisabled(false);
      setCounter(60);
    }

    // Clean up the timeout
    return () => {
      clearTimeout(timer);
    };
  }, [isDisabled, counter]);

  useEffect(() => {
    if (registerConfirmLock === LoadingStatus.LOADED) {
      navigate('/auth/details');
    }
  }, [registerConfirmLock]);

  // Convert the counter value to minutes and seconds
  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;

  // Format the seconds to always show two digits
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <Card
      style={{
        width: 368,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete='off'>
          <div className='flex flex-col gap-2 mb-6'>
            <Title level={4}>Подтвердите e-mail</Title>
            <Text>Мы выслали код подтверждения на {user?.email}</Text>
          </div>

          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-2'>
              <InputForm name='code' placeholder='Код из письма' size='large' autoComplete='off' />
            </div>
            <div className='flex flex-col gap-2'>
              <Button htmlType='submit' type='primary' block>
                Продолжить
              </Button>
              <Button
                htmlType='button'
                type='primary'
                block
                onClick={() => {
                  handleNewCodeRequest();
                  setIsDisabled(true);
                }}
              >
                Получить новый код{' '}
                {isDisabled && '(' + minutes + ':' + formattedSeconds + ')'}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
