import React, { useState } from 'react';
import {
  Controller,
  FieldValues,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Button, Input, Table, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { AddIcon } from '@/icons/AddIcon';
import { DeleteIcon } from '@/icons/DeleteIcon';

interface RowData {
  Mi: number;
  mi: number;
  Qp_ni: number;
}

const { Text, Title } = Typography;

interface IDynamicTable {
  handleChange: () => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const DynamicRoomOpeningTable: React.FC<IDynamicTable> = ({
  handleChange,
  handleBlur,
}) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'room_opening',
  });

  const handleAddRow = () => {
    append({ ai: 0, hi: 0, Ai: 0 });
    handleChange();
  };

  const handleRemoveRow = (index: number) => {
    remove(index);
    handleChange();
  };

  const columns = [
    {
      title: 'i',
      dataIndex: 'i',
      render: (text: string, record: any, index: number) => (
        <Text>{index + 1}</Text>
      ),
    },
    {
      title: (
        <i>
          a<sub>i</sub>(м)
        </i>
      ),
      dataIndex: 'ai',
      render: (text: string, record: any, index: number) => (
        <InputNumberForm
          name={`room_opening[${index}].ai`}
          onBlur={handleBlur}
        />
      ),
    },
    {
      title: (
        <i>
          h<sub>i</sub> (м)
        </i>
      ),
      dataIndex: 'hi',
      render: (text: string, record: any, index: number) => (
        <InputNumberForm
          name={`room_opening[${index}].hi`}
          onBlur={handleBlur}
        />
      ),
    },
    {
      title: (
        <i>
          A <sub>i</sub>(м<sup>2</sup>)
        </i>
      ),
      dataIndex: 'Ai',
    },
    {
      title: '',
      render: (_: any, record: any, index: number) => (
        <div className='flex gap-2'>
          <Button onClick={handleAddRow} className='py-1 px-2.5'>
            <AddIcon />
          </Button>
          <Button
            onClick={() => handleRemoveRow(index)}
            className='py-1 px-2.5'
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={fields}
        columns={columns}
        pagination={false}
        rowKey='id'
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={5}>
                <Text strong>
                  a<sub>i</sub>{' '}
                </Text>
                <Text>– ширина i-го проема, м</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={5}>
                <Text strong>
                  h<sub>i</sub>{' '}
                </Text>
                <Text>– высота i-го проема, м</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={5}>
                <Text strong>
                  A<sub>i</sub>{' '}
                </Text>
                <Text>– площадь i-го проема, м2</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );
};
