import { Controller, useFormContext } from 'react-hook-form';
import { Input, InputProps, Typography } from 'antd';

interface InputForm extends InputProps {
  label?: string;
  name: string;
  defaultValue?: string;
}

export const InputForm: React.FC<InputForm> = (props) => {
  const { Text } = Typography;

  const { label, name, defaultValue } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <>
      <div className='flex flex-col gap-1 w-full'>
        {label && (
          <label htmlFor={name} className='text-secondary'>
            {label}
          </label>
        )}
        <Controller
          render={({ field }) => (
            <Input {...field} {...props} status={error && 'error'} />
          )}
          control={control}
          name={name}
          defaultValue={defaultValue}
        />
        {error && (
          <Text className='block' type='danger'>
            {String(error.message)}
          </Text>
        )}
      </div>
    </>
  );
};
