import { Button, Modal, Table, TableColumnsType, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemModalsSelectors } from '@/core/redux/slices/modals/smokeExtraction/system/selectors';
import {
  IResult,
  systemModalsActions,
} from '@/core/redux/slices/modals/smokeExtraction/system/slice';

const ModalContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isRender, payload } = useAppSelector(
    systemModalsSelectors.displayResultModal
  );

  const handleCancel = () => {
    dispatch(systemModalsActions.closeDisplayResultModal());
  };

  const columns: TableColumnsType<IResult> = [
    {
      title: 'Участок',
      dataIndex: 'i',
    },
    {
      title: <i>h, м</i>,
      dataIndex: 'h',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: <i>Ps, Па</i>,
      dataIndex: 'Ps',
      render: (text) => Number(text).toFixed(1),
    },
    {
      title: <i>vs, Па</i>,
      dataIndex: 'vs',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: <i>Gs, кг/с</i>,
      dataIndex: 'Gs',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: <i>ΔGsd, кг/с</i>,
      dataIndex: 'dGsd',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: <i>ΔGsw, кг/с</i>,
      dataIndex: 'dGsw',
      render: (text) => Number(text).toFixed(2),
      hidden: payload?.systemTypeID === 5 || payload?.systemTypeID === 4 || payload?.currentView === 'Подземные'
    },
    {
      title: <i>ΔGs, кг/с</i>,
      dataIndex: 'dGs',
      render: (text) => Number(text).toFixed(2),
    },
  ];

  return (
    <Modal
      open={isRender}
      destroyOnClose
      onCancel={handleCancel}
      centered
      title='Результирующая таблица'
      footer={null}
      width={1024}
    >
      {payload && (
        <Table
          columns={columns}
          dataSource={payload.resultTableData}
          pagination={false}
        />
      )}
    </Modal>
  );
};

export const DisplayResultModal: React.FC = () => {
  const { isRender } = useAppSelector(systemModalsSelectors.displayResultModal);

  if (isRender) {
    return <ModalContent />;
  }

  return null;
};
