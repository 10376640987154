import React, { useMemo, useRef, useState } from 'react';
import type { SelectProps } from 'antd';
import { Select, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import debounce from 'lodash/debounce';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => void;
  options: DefaultOptionType[];
  loading: boolean;
  debounceTimeout?: number;
}

export function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  fetchOptions,
  debounceTimeout = 400,
  loading,
  options,
  ...props
}: DebounceSelectProps<ValueType>) {
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchOptions(value);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      loading={loading}
      notFoundContent={loading ? <Spin size='small' /> : null}
      options={options}
      {...props}
      optionLabelProp='label'
      onChange={props.onChange}
    />
  );
}
