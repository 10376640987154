import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card, Spin, Typography } from 'antd';
import { twMerge } from 'tailwind-merge';

import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smokeHatchesModalsActions } from '@/core/redux/slices/modals/smokeExtraction/smokeHatches/slice';
import { productsSelectors } from '@/core/redux/slices/products/selectors';
import {
  IManufacture,
  IProduct,
  productsActions,
} from '@/core/redux/slices/products/slice';

import { ProductsList } from './ProductsList/ProductsList';

const { Text } = Typography;

interface IManufactureCard {
  id: number;
  name: string;
  logoURL: string;
  description: string;
  onSelect: (manufacture: IManufacture) => void;
  className?: string;
  isActive?: boolean;
}

interface ISelectView {
  position: string;
  productType: string;
}

const ManufactureCard: React.FC<IManufactureCard> = ({
  id,
  name,
  logoURL,
  description,
  onSelect,
  className,
  isActive = false,
}) => {
  const manufacture = {
    id: id,
    name: name,
    logo_url: logoURL,
    description: description,
  };

  return (
    <div
      onClick={() => onSelect(manufacture)}
      className={twMerge(
        'bg-white border-2  border-primary-second w-32 h-20 items-center justify-center flex rounded-lg overflow-hidden cursor-pointer',
        isActive && 'border-primary',
        className
      )}
    >
      <img src={logoURL} alt={name} className='object-cover w-auto h-auto' />
    </div>
  );
};

export const SelectView: React.FC<ISelectView> = ({
  position,
  productType,
}) => {
  const dispatch = useAppDispatch();

  const manufactures = useAppSelector(productsSelectors.manufacturers);
  const products = useAppSelector(productsSelectors.prdoucts);

  const prdouctsLock = useAppSelector(productsSelectors.prdouctsLock);

  const selectedManufacture = useAppSelector(
    productsSelectors.selectedManufacture
  );

  const handleManufactureSelect = (manufacture: IManufacture) => {
    dispatch(productsActions.setSelectedManufacture(manufacture));
  };

  const handleOpenDisplayHatchType = (product: IProduct) => {
    dispatch(
      smokeHatchesModalsActions.openDiplayHatchTypeModal({
        ...product,
        manufacturer_name: selectedManufacture?.name || '',
      })
    );
  };

  const handleSelectHatchType = (product: IProduct) => {
    dispatch(productsActions.setSelectedProduct(product));
  };

  useEffect(() => {
    dispatch(
      productsActions.fetchManufactures({
        productType: productType,
      })
    );
  }, []);

  useEffect(() => {
    if (!selectedManufacture) {
      return;
    }

    dispatch(
      productsActions.fetchProducts({
        position: position,
        manufactureID: selectedManufacture.id,
      })
    );
  }, [selectedManufacture]);

  return (
    <Card
      className='bg-primary-light border-primary'
      classNames={{
        body: '!p-4',
      }}
    >
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <Text>Выберите производителя</Text>
          <div className='flex gap-2'>
            {manufactures.map((manufacture) => (
              <ManufactureCard
                key={manufacture.id}
                id={manufacture.id}
                name={manufacture.name}
                description={manufacture.description}
                logoURL={manufacture.logo_url}
                onSelect={handleManufactureSelect}
                isActive={
                  selectedManufacture
                    ? manufacture.id === selectedManufacture.id
                    : false
                }
              />
            ))}
          </div>
        </div>

        {selectedManufacture && (
          <div className='flex flex-col gap-2'>
            <Text>Выберите тип дымового люка</Text>

            {prdouctsLock === LoadingStatus.LOADING && (
              <div className='flex items-center justify-center h-[395px]'>
                <Spin />
              </div>
            )}
            {prdouctsLock === LoadingStatus.LOADED && (
              <ProductsList
                products={products}
                onDisplayHatchType={handleOpenDisplayHatchType}
                onSelectHatchType={handleSelectHatchType}
              />
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
