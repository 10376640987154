import { Button, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

const { Text, Title } = Typography;

interface IFanStrapping {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleOpenResultTable: () => void;

}

export const FanStrapping: React.FC<IFanStrapping> = ({ handleBlur, handleOpenResultTable }) => {
  return (
    <div>
      <Button type='primary' onClick={handleOpenResultTable} className='mb-6'>
        Результирующая таблица
      </Button>

      <Title level={5}>Параметры подключения вентилятора</Title>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          addonBefore={
            <i>
              G<sub>l</sub> =
            </i>
          }
          name='Gl'
          addonAfter={<i>кг/с</i>}
          label='Требуемый по расчету суммарный расход воздуха, подаваемый в защищаемую лифтовую шахту'
          placeholder='Введите значение'
          fixValue={2}
          onBlur={handleBlur}
          disabled
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              P<sub>dl</sub> =
            </i>
          }
          name='Pdl'
          addonAfter={<i>Па</i>}
          label='Cуммарное сопротивление присоединительных воздуховодов при температуре ta'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
      </div>
    </div>
  );
};
