import { useEffect } from 'react';
import { Pagination } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { airExchangeSelectors } from '@/core/redux/slices/airExchange/selectors';
import { airExchangeActions } from '@/core/redux/slices/airExchange/slice';

import { AirExchangeItem } from './AirExchangeItem';

interface IAirExchangeList {
  currentPage: number;
  onPaginationChange: (current: number, size: number) => void;
}
export const AirExchangeList: React.FC<IAirExchangeList> = ({
  currentPage,
  onPaginationChange,
}) => {
  const airExchangeItems = useAppSelector(
    airExchangeSelectors.airExchangeItems
  );
  const airExchangeTotal = useAppSelector(
    airExchangeSelectors.airExchangeTotal
  );

  return (
    <div className='flex flex-col gap-8'>
      {airExchangeItems && airExchangeItems.length
        ? airExchangeItems.map((item) => (
            <AirExchangeItem key={item.id} {...item} />
          ))
        : 'Не найдено'}

      {airExchangeTotal !== null &&
        airExchangeTotal !== undefined &&
        airExchangeTotal !== 0 && (
          <div className='flex justify-end'>
            <Pagination
              current={currentPage}
              total={airExchangeTotal}
              onChange={onPaginationChange}
              defaultPageSize={5}
            />
          </div>
        )}
    </div>
  );
};
