import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.systemModals;

const displayResultModal = createSelector(
  [selector],
  (state) => state.displayResultModal
);

const elevatorShaftsResultModal = createSelector(
  [selector],
  (state) => state.elevatorShaftsResultModal
);

export const systemModalsSelectors = {
  displayResultModal,
  elevatorShaftsResultModal,
};
