import { Link } from 'react-router-dom';
import { Typography } from 'antd';

const { Text } = Typography;

export const Footer: React.FC = () => {
  return (
    <div className='p-6 flex justify-end gap-4'>
      <Link to='https://storage.yandexcloud.net/aeropro-static/user_agreement_aeropro_expert.pdf' target='_blank' rel='noreferrer'>
        <Text type='secondary' underline>
          Пользовательское соглашение
        </Text>
      </Link>
      <Link to='https://storage.yandexcloud.net/aeropro-static/privacypolicy_%20aeropro_expert.pdf' target='_blank' rel='noreferrer'>
        <Text type='secondary' underline>
          Политика конфиденциальности
        </Text>
      </Link>
    </div>
  );
};
