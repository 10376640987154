import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.airExchange;

//list
const airExchangeList = createSelector(
  [selector],
  (state) => state.airExchangeList
);
const airExchangeItems = createSelector(
  [selector],
  (state) => state.airExchangeList?.items
);
const airExchangeTotal = createSelector(
  [selector],
  (state) => state.airExchangeList?.total
);

const airExchangeListLock = createSelector(
  [selector],
  (state) => state.airExchangeListLock
);

export const airExchangeSelectors = {
  airExchangeList,
  airExchangeListLock,
  airExchangeItems,
  airExchangeTotal,
};
