import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.smokeHatchesModals;

const displayHatchTypeModal = createSelector(
  [selector],
  (state) => state.displayHatchTypeModal
);

const selectSmokeHatchModal = createSelector(
  [selector],
  (state) => state.selectSmokeHatchModal
);

export const smokeHatchesModalsSelectors = {
    displayHatchTypeModal,
  selectSmokeHatchModal,
};
