import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import {
  IManufacturesFetchPayload,
  IProductsFetchPayload,
  productsActions,
} from './slice';

// type ManufacturesResponse = OASOutput<
//   NormalizeOAS<typeof oas>,
//   '/products/manufacturers',
//   'get',
//   '200'
// >;

// type ProductsResponse = OASOutput<
//   NormalizeOAS<typeof oas>,
//   '/products',
//   'get',
//   '200'
// >;

function* fetchManufactures(
  action: PayloadAction<IManufacturesFetchPayload>
): Generator<any, void, any> {
  const { productType, orderBy, page, size } = action.payload;

  yield put(productsActions.setManufacturesLock(LoadingStatus.LOADING));

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const response = yield call(restCall, '/products/manufacturers', 'get', {
      query: {
        product_type: productType,
        order_by: orderBy,
        page: page,
        size: size,
      },
      ...authAdd(),
    });

    const manufactures: any[] = response.items;

    yield put(productsActions.setManufactures(manufactures));
    yield put(productsActions.setManufacturesLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on manufactures fetching');
    yield put(productsActions.setManufacturesLock(LoadingStatus.ERROR));
  }
}

function* fetchProducts(
  action: PayloadAction<IProductsFetchPayload>
): Generator<any, void, any> {
  const { position, orderBy, manufactureID, page, size } = action.payload;

  yield put(productsActions.setProductsLock(LoadingStatus.LOADING));

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const response = yield call(restCall, '/products', 'get', {
      query: {
        position: position,
        order_by: orderBy,
        manufacturer_id: manufactureID,
        page: page,
        size: size,
      },
      ...authAdd(),
    });

    const products: any[] = response.items;

    yield put(productsActions.setProducts(products));
    yield put(productsActions.setProductsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on products fetching');
    yield put(productsActions.setProductsLock(LoadingStatus.ERROR));
  }
}

export const productsSagas = [
  takeLatest(productsActions.fetchProducts, fetchProducts),
  takeLatest(productsActions.fetchManufactures, fetchManufactures),
];
