import { FormProvider, useForm } from 'react-hook-form';
import { Button, Modal, Segmented, Typography } from 'antd';

import { RadioGroupForm } from '@/components/form/Radio';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smokeHatchesModalsSelectors } from '@/core/redux/slices/modals/smokeExtraction/smokeHatches/selectors';
import { smokeHatchesModalsActions } from '@/core/redux/slices/modals/smokeExtraction/smokeHatches/slice';
import HatchTypeSample from '@/images/samples/hatch-type.png';

import styles from './styles.module.scss';

const { Text, Paragraph } = Typography;

export const ModalContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();

  const { isRender, payload } = useAppSelector(
    smokeHatchesModalsSelectors.selectSmokeHatchModal
  );

  const handleCancel = () => {
    dispatch(smokeHatchesModalsActions.closeSelectSmokeHatchModal());
  };

  const coverTypeOptions = [
    { label: 'Утепленная непрозрачная', value: 'Утепленная непрозрачная' },
    { label: 'Прозрачная однослойная', value: 'Прозрачная однослойная' },
    {
      label: 'Архитектурная прозрачная с 3-слойным куполом',
      value: 'Архитектурная прозрачная с 3-слойным куполом',
    },
  ];

  const executionOptions = [
    { label: 'Стандартное', value: 'Стандартное' },
    { label: 'Модульное', value: 'Модульное' },
    { label: 'Модульно-левое', value: 'Модульно-левое' },
    { label: 'Модульно-правое', value: 'Модульно-правое' },
  ];

  const extraOptions = [
    { label: 'Нет', value: 'Нет' },
    { label: 'Решетка защитная', value: 'Решетка защитная' },
  ];

  const renderHeader = (
    <>
      <div className='flex gap-2 mb-2'>
        <img src={HatchTypeSample} className='w-[42px] h-[42px]' />
        <div className='flex gap-6'>
          <div className='flex flex-col'>
            <Text type='secondary'>Веза</Text>
            <Text strong>ДЫМОЗОР - 500 - 200*300 - П - 1600 - 24 - Р - С</Text>
          </div>
        </div>
      </div>
      <div className='ml-[50px]'>
        <div className='flex flex-col gap-2'>
          <div className='flex'>
            <Text type='secondary' className='block w-[128px]'>
              Размер, мм
            </Text>
            <Text strong>200 × 300</Text>
          </div>
          <div className='flex'>
            <Text type='secondary' className='block w-[128px]'>
              Количество
            </Text>
            <Text strong>2 шт</Text>
          </div>
        </div>
        <FormProvider {...methods}>
          <form className='mt-4 flex flex-col gap-4'>
            <div className='flex flex-col gap-2'>
              <Text type='secondary'>Тип крышки</Text>
              <RadioGroupForm
                name='cover'
                defaultValue='Утепленная непрозрачная'
                className='font-normal'
                options={coverTypeOptions}
                vertical
              />
            </div>
            <div>
              <Text type='secondary'>Исполнение</Text>
              <RadioGroupForm
                name='execution'
                defaultValue='Стандартное'
                className='font-normal'
                options={executionOptions}
                vertical
              />
            </div>
            <div className='flex flex-col gap-2'>
              <Text type='secondary'> Опции</Text>
              <RadioGroupForm
                name='extra'
                defaultValue='Нет'
                className='font-normal'
                options={extraOptions}
                vertical
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );

  const renderFooter = (
    <div className='flex justify-between'>
      <Button onClick={handleCancel}>Закрыть</Button>
      <Button type='primary' onClick={handleCancel}>
        Добавить в ТКП
      </Button>
    </div>
  );

  return (
    <Modal
      open={isRender}
      destroyOnClose
      onCancel={handleCancel}
      centered
      title={renderHeader}
      footer={renderFooter}
      className={styles.modal}
    >
      <div className='flex flex-col gap-2.5 items-start'>
        <Segmented<string>
          options={['Описание', 'Характеристики']}
          onChange={(value) => {
            console.log(value); // string
          }}
        />
        <Paragraph>
          ДЫМОЗОР® - 100 предназначен для монтажа на плоскую кровлю здания либо
          с углом ската до 14 градусов и использования в системах противодымной
          вентиляции с естественным побуждением тяги. Рекомендуется для
          установки на одноэтажные здания большой площади. Кроме основного
          назначения – удаления продуктов горения, может быть использован для
          проветривания помещения. В варианте с прозрачной крышкой имеет функцию
          дополнительного естественного освещения помещения.
        </Paragraph>
      </div>
    </Modal>
  );
};

export const SelectSmokeHatchModal: React.FC = () => {
  const { isRender } = useAppSelector(
    smokeHatchesModalsSelectors.selectSmokeHatchModal
  );

  if (isRender) {
    return <ModalContent />;
  }

  return null;
};
