import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthLayout } from '@/layouts/AuthLayout/AuthLayout';
import { PublicLayout } from '@/layouts/PublicLayout/PublicLayout';
import DetailsRoute from '@/pages/auth/DetailsRoute';
import LoginRoute from '@/pages/auth/LoginRoute';
import RegisterConfirmRoute from '@/pages/auth/RegisterConfirmRoute';
import ResetPasswordConfirmRoute from '@/pages/auth/ResetPasswordConfirmRoute';
import ResetPasswordRoute from '@/pages/auth/ResetPasswordRoute';
import SignUpRoute from '@/pages/auth/SignUpRoute';

const AuthRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route element={<AuthLayout />}>
          <Route path='/login' element={<LoginRoute />} />
          <Route path='/signup' element={<SignUpRoute />} />
        </Route>
        <Route path='/confirm' element={<RegisterConfirmRoute />} />
        <Route path='/details' element={<DetailsRoute />} />
        <Route path='/reset' element={<ResetPasswordRoute />} />
        <Route path='/reset/confirm' element={<ResetPasswordConfirmRoute />} />
        <Route path={'*'} element={<Navigate to={'/login'} />} />
      </Route>
    </Routes>
  );
};

export default AuthRootRoute;
