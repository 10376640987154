import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';

interface IArrivalTimeCalculation {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handlePerformCalculation: () => void;
}

const { Title } = Typography;

export const ArrivalTimeCalculation: React.FC<IArrivalTimeCalculation> = ({
  handleBlur,
  handlePerformCalculation,
}) => {
  const fireLoadOptions = [
    {
      label:
        'Административные помещения, учебные классы школ, ВУЗов, кабинеты поликлиник',
      value:
        'Административные помещения, учебные классы школ, ВУЗов, кабинеты поликлиник',
    },
    {
      label: 'Гардероб верхней одежды',
      value: 'Гардероб верхней одежды',
    },
    {
      label: 'Библиотеки, архивы',
      value: 'Библиотеки, архивы',
    },
    {
      label: 'Зал театра,кинотеатра, клуба, цирка и т.д.',
      value: 'Зал театра,кинотеатра, клуба, цирка и т.д.',
    },
    {
      label: 'Промтовары; текстильные изделия',
      value: 'Промтовары; текстильные изделия',
    },
  ];

  const locationOptions = [
    {
      label: 'В городских поселениях и городских округах',
      value: 'В городских поселениях и городских округах',
    },
    {
      label: 'В сельских поселениях',
      value: 'В сельских поселениях',
    },
  ];
  return (
    <div>
      {/* <Title level={5}>Расчет по времени прибытия пожарных подразделений</Title> */}

      <div className='flex flex-col gap-4'>
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            name='nu'
            addonBefore={<i>η =</i>}
            label='Полнота сгорания пожарной нагрузки'
            onBlur={handleBlur}
            size='large'
          />
        </div>
        {/* <div className='py-4'>
        <RadioGroupForm
          name='fire_load'
          label='Пожарная нагрузка'
          options={fireLoadOptions}
          onChange={handlePerformCalculation}
          className='flex flex-col gap-2'
        />
      </div> */}

        <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            name='Qp_nsr'
            addonBefore={
              <i>
                Q<sup>p</sup>
                <sub>нср</sub> =
              </i>
            }
            addonAfter={<i>МДж/кг</i>}
            label='Низшая теплота сгорания пожарной нагрузки'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            name='psi_sr'
            addonBefore={
              <i>
                ψ<sub>ср</sub> =
              </i>
            }
            addonAfter={
              <i>
                кг/(м<sup>2</sup>с)
              </i>
            }
            label='Удельная скорость выгорания'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            name='vl'
            addonBefore={
              <i>
                v<sub>л</sub> =
              </i>
            }
            addonAfter={<i>м/с</i>}
            label='Линейная скорость распространения пламени'
            onBlur={handleBlur}
            size='large'
          />
        </div>
        {/* <div className='py-4'>
        <RadioGroupForm
          name='location'
          label='Местоположение объекта'
          options={locationOptions}
          onChange={handlePerformCalculation}
          className='flex flex-col gap-2'
        />
      </div> */}
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            name='tau'
            addonBefore={<i>τ =</i>}
            addonAfter={<i>мин</i>}
            label='Время прибытия пожарных подразделений'
            note='В городских населенных пунктах не более 10 мин, в сельских - не более 20 мин'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            name='Spom'
            addonBefore={
              <i>
                S<sub>пом</sub> =
              </i>
            }
            addonAfter={
              <i>
                м<sup>2</sup>
              </i>
            }
            label='Площадь пола горящего помещения'
            onBlur={handleBlur}
            size='large'
            fixValue={1}
          />
          <InputNumberForm
            name='F0'
            addonBefore={
              <i>
                F<sub>0</sub> =
              </i>
            }
            addonAfter={
              <i>
                м<sup>2</sup>
              </i>
            }
            label='Расчётная площадь горения пожарной нагрузки'
            onBlur={handleBlur}
            fixValue={1}
            disabled
            size='large'
          />
          <InputNumberForm
            name='Qf'
            addonBefore={
              <i>
                Q<sub>f</sub> =
              </i>
            }
            addonAfter={<i>кВт</i>}
            label='Мощность тепловыделения очага пожара'
            onBlur={handleBlur}
            disabled
            fixValue={0}
            size='large'
          />
        </div>
      </div>
    </div>
  );
};
