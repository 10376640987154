import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.system.root;

//system
const system = createSelector([selector], (state) => state.system);
const systemLock = createSelector([selector], (state) => state.systemLock);
const systemState = createSelector([selector], (state) => state.system?.state);
//systemList
const systemList = createSelector([selector], (state) => state.systemList);
const systemListItems = createSelector(
  [selector],
  (state) => state.systemList?.items
);
const systemListTotal = createSelector(
  [selector],
  (state) => state.systemList?.total
);

//add system
const addSystemLock = createSelector(
  [selector],
  (state) => state.addSystemLock
);
const addSystemStatus = createSelector(
  [selector],
  (state) => state.addSystemLock.status
);
const addSystemResponse = createSelector(
  [selector],
  (state) => state.addSystemLock.reponse
);

//delete system

const updateSystemLock = createSelector(
  [selector],
  (state) => state.updateSystemLock
);

//report

const isReportReady = createSelector(
  [selector],
  (state) => state.isReportReady
);

export const systemSelectors = {
  system,
  systemLock,
  systemState,

  addSystemLock,
  addSystemStatus,
  addSystemResponse,

  systemList,
  systemListItems,
  systemListTotal,

  updateSystemLock,

  isReportReady,
};
