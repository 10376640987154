import { InputNumberForm } from '@/components/form/InputNumber';

export interface ISmokeLayerCharacteristics {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const SmokeLayerCharacteristics: React.FC<
  ISmokeLayerCharacteristics
> = ({ handleBlur }) => {
  return (
    <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
      <InputNumberForm
        name='alpha'
        addonBefore={<i>α =</i>}
        addonAfter={
          <i>
            кВт/(м<sup>2</sup>*°К)
          </i>
        }
        label='Коэффициент теплоотдачи дымового слоя'
        disabled
        onBlur={handleBlur}
        size='large'
        fixValue={4}
      />
      <InputNumberForm
        name='cpsm'
        addonBefore={
          <i>
            c<sub>psm</sub> =
          </i>
        }
        addonAfter={<i>кДж/(кг*°К)</i>}
        label='Удельная теплоемкость дымового слоя при температуре Tsm'
        disabled
        onBlur={handleBlur}
        fixValue={3}
        size='large'
      />
      <InputNumberForm
        name='Tsm'
        addonBefore={
          <i>
            T<sub>sm</sub>=
          </i>
        }
        addonAfter={<i>°К</i>}
        label='Cредняя температура дымового слоя'
        fixValue={1}
        disabled
        onBlur={handleBlur}
        size='large'
      />
      <InputNumberForm
        name='tsm'
        addonBefore={
          <i>
            t<sub>sm</sub>=
          </i>
        }
        addonAfter={<i>°C</i>}
        label='Cредняя температура дымового слоя'
        fixValue={1}
        disabled
        onBlur={handleBlur}
        size='large'
      />
      <InputNumberForm
        name='psm'
        addonBefore={
          <i>
            ρ<sub>sm</sub>=
          </i>
        }
        addonAfter={
          <i>
            кг/м<sup>3</sup>
          </i>
        }
        label='Плотность дымового слоя'
        disabled
        onBlur={handleBlur}
        size='large'
      />
      <div></div>
      <InputNumberForm
        name='Gsm'
        addonBefore={
          <i>
            G<sub>sm</sub>=
          </i>
        }
        addonAfter='кг/с'
        label='Массовый расход удаляемых продуктов горения'
        fixValue={2}
        disabled
        onBlur={handleBlur}
        size='large'
      />
      <InputNumberForm
        name='Lsm'
        addonBefore={
          <i>
            L<sub>sm</sub>=
          </i>
        }
        addonAfter={
          <i>
            м<sup>3</sup>/ч
          </i>
        }
        label='Объемный расход удаляемых продуктов горения'
        disabled
        onBlur={handleBlur}
        size='large'
      />
    </div>
  );
};
