import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import {
  calculationActions,
  ICalculation,
  ICalculationAddPayload,
  ICalculationCopyPayload,
  ICalculationDeletePayload,
  ICalculationFetchPayload,
  ICalculationList,
  ICalculationListFetchPayload,
  ICalculationUpdatePayload,
} from './slice';

type CalculationListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/building_object/{building_object_id}/calculation',
  'get',
  '200'
>;

type CalculationResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/calculation/{calculation_id}',
  'get',
  '200'
>;

type CalculationAddRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/building_object/{building_object_id}/calculation',
  'post'
>;

type CalculationUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/calculation/{calculation_id}',
  'put'
>;

type CalculationDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/calculation/{calculation_id}',
  'delete'
>;

type CalculationCopyRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/calculation/{calculation_id}/copy',
  'post'
>;

function* fetchCalculationList(
  action: PayloadAction<ICalculationListFetchPayload>
): Generator<any, void, CalculationListResponse> {
  const { payload } = action;

  yield put(calculationActions.setCalculationListLock(LoadingStatus.LOADING));

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/building_object/{building_object_id}/calculation',
      'get',
      {
        params: {
          building_object_id: payload['building_object_id'],
        },
        query: {
          created_at__gte: payload?.['created_at__gte'],
          created_at__lte: payload?.['created_at__lte'],
          order_by: payload?.['order_by'],
          page: payload?.['page'],
          size: payload?.['size'],
        },
        ...authAdd(),
      }
    );

    const calculationList: ICalculationList = response;

    yield put(calculationActions.setCalculationList(calculationList));
    yield put(calculationActions.setCalculationListLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on calculation fetching');
    yield put(calculationActions.setCalculationListLock(LoadingStatus.ERROR));
  }
}

function* fetchCalculation(
  action: PayloadAction<ICalculationFetchPayload>
): Generator<any, void, CalculationResponse> {
  const { calculationID } = action.payload;

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/calculation/{calculation_id}',
      'get',
      {
        params: {
          calculation_id: calculationID,
        },
        ...authAdd(),
      }
    );

    const calculation: ICalculation = response;

    yield put(calculationActions.setCalculation(calculation));
  } catch (error) {
    console.log('Error on calculation fetching');
  }
}

function* addCalculation(
  action: PayloadAction<ICalculationAddPayload>
): Generator<any, void, any> {
  const { building_object_id, object_address, object_name, calc_name } =
    action.payload;

  yield put(
    calculationActions.setAddCalculationLock({
      status: LoadingStatus.LOADING,
      response: null,
    })
  );

  const request: CalculationAddRequest = {
    params: {
      building_object_id: building_object_id,
    },
    json: {
      calc_name: calc_name,
      object_name: object_name,
      object_address: object_address,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/building_object/{building_object_id}/calculation',
      'post',
      request
    );

    yield put(
      calculationActions.setAddCalculationLock({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log('Error on create calculation', error);
    yield put(
      calculationActions.setAddCalculationLock({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* updateCalculation(
  action: PayloadAction<ICalculationUpdatePayload>
): Generator<any, void, any> {
  const { calculationID, object_address, object_name, calc_name } =
    action.payload;

  yield put(
    calculationActions.setAddCalculationLock({
      status: LoadingStatus.LOADING,
      response: null,
    })
  );

  const request: CalculationUpdateRequest = {
    params: {
      calculation_id: calculationID,
    },
    json: {
      calc_name: calc_name,
      object_name: object_name,
      object_address: object_address,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/calculation/{calculation_id}',
      'put',
      request
    );

    yield put(
      calculationActions.setAddCalculationLock({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log('Error on create calculation', error);
    yield put(
      calculationActions.setAddCalculationLock({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* deleteCalculation(
  action: PayloadAction<ICalculationDeletePayload>
): Generator<any, void, any> {
  const { calculationID } = action.payload;

  yield put(calculationActions.setUpdateCalculationLock(LoadingStatus.LOADING));

  const request: CalculationDeleteRequest = {
    params: {
      calculation_id: calculationID,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/calculation/{calculation_id}',
      'delete',
      request
    );

    yield put(
      calculationActions.setUpdateCalculationLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on delete calculation', error);
    yield put(calculationActions.setUpdateCalculationLock(LoadingStatus.ERROR));
  }
}

function* copyCalculation(
  action: PayloadAction<ICalculationCopyPayload>
): Generator<any, void, any> {
  const { calculationID } = action.payload;

  yield put(calculationActions.setUpdateCalculationLock(LoadingStatus.LOADING));

  const request: CalculationCopyRequest = {
    params: {
      calculation_id: calculationID,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/calculation/{calculation_id}/copy',
      'post',
      request
    );

    yield put(
      calculationActions.setUpdateCalculationLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on copy calculation', error);
    yield put(calculationActions.setUpdateCalculationLock(LoadingStatus.ERROR));
  }
}

export const calculationSagas = [
  takeLatest(calculationActions.fetchCalculation, fetchCalculation),
  takeLatest(calculationActions.fetchCalculationList, fetchCalculationList),
  takeLatest(calculationActions.addCalculation, addCalculation),
  takeLatest(calculationActions.updateCalculation, updateCalculation),
  takeLatest(calculationActions.deleteCalculation, deleteCalculation),
  takeLatest(calculationActions.copyCalculation, copyCalculation),
];
