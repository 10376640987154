import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

export interface ICalculation {
  calc_name?: string | null;
  id: number;
  created_at: string;
  updated_at: string;
  object_name: string;
  object_address: string;
}

export interface ICalculationLock {
  status: LoadingStatus;
  response: ICalculation | null;
}

export interface ICalculationItem {
  calc_name?: string | null;
  id: number;
  object_name: string;
  object_address: string;
  created_at: string;
  updated_at: string;
  systems_count: number;
}

export interface ICalculationList {
  pages?: number | null | undefined;
  page: number | null;
  size: number | null;
  items: ICalculationItem[];
  total: number | null;
  links: {
    first: string | null;
    last: string | null;
    self: string | null;
    next: string | null;
    prev: string | null;
  };
}

export interface ICalculationFetchPayload {
  calculationID: number;
}
export interface ICalculationListFetchPayload {
  building_object_id: number;
  created_at__gte?: string;
  created_at__lte?: string;
  order_by?: string;
  page?: number;
  size?: number;
}

export interface ICalculationAddPayload {
  building_object_id: number;
  calc_name?: string;
  object_name: string;
  object_address: string;
}

export interface ICalculationUpdatePayload {
  calculationID: number;
  calc_name?: string;
  object_name: string;
  object_address: string;
}

export interface ICalculationDeletePayload {
  calculationID: number;
}

export interface ICalculationCopyPayload {
  calculationID: number;
}

export interface ICalculationState {
  calculation: ICalculation | null;
  calculationList: ICalculationList | null;
  calculationListLock: LoadingStatus;
  updateCalculationLock: LoadingStatus;
  addCalculationLock: ICalculationLock;
}

const initialState: ICalculationState = {
  calculation: null,
  calculationList: null,
  calculationListLock: LoadingStatus.NEVER,
  updateCalculationLock: LoadingStatus.NEVER,
  addCalculationLock: {
    status: LoadingStatus.NEVER,
    response: null,
  },
};

export const calculationSlice = createSlice({
  name: 'calculation',
  initialState,
  reducers: {
    fetchCalculation: (
      state,
      action: PayloadAction<ICalculationFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setCalculation: (state, action: PayloadAction<ICalculation | null>) => ({
      ...state,
      calculation: action.payload,
    }),
    //calculation list
    fetchCalculationList: (
      state,
      action: PayloadAction<ICalculationListFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setCalculationList: (
      state,
      action: PayloadAction<ICalculationList | null>
    ) => ({
      ...state,
      calculationList: action.payload,
    }),
    setCalculationListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      calculationListLock: action.payload,
    }),
    // add
    addCalculation: (state, action: PayloadAction<ICalculationAddPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAddCalculationLock: (
      state,
      action: PayloadAction<ICalculationLock>
    ) => ({
      ...state,
      addCalculationLock: action.payload,
    }),
    // update
    updateCalculation: (state, action: PayloadAction<ICalculationUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // delete
    deleteCalculation: (
      state,
      action: PayloadAction<ICalculationDeletePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    // copy
    copyCalculation: (
      state,
      action: PayloadAction<ICalculationDeletePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateCalculationLock: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => ({
      ...state,
      updateCalculationLock: action.payload,
    }),
  },
});

export const calctulationReducer = calculationSlice.reducer;
export const calculationActions = calculationSlice.actions;
