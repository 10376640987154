import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Table, TableColumnsType, Typography } from 'antd';

import { Breadcrumbs } from '@/components/ui/Breadcrumbs/Breadcrumbs';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { buildingObjectModalsSelectors } from '@/core/redux/slices/modals/smokeExtraction/buildingObject/selectors';
import { buildingObjectModalsActions } from '@/core/redux/slices/modals/smokeExtraction/buildingObject/slice';
import { buildingObjectSelectors } from '@/core/redux/slices/smokeExtraction/buildingObject/selectors';
import {
  buildingObjectActions,
  IBuildingObject,
} from '@/core/redux/slices/smokeExtraction/buildingObject/slice';
import { formatToDate } from '@/core/utils/dateUtils';

const defaultPageSize = 10;

interface ITableHeader {
  onSearchChange: (newValue: string) => void;
}

const { Text, Title } = Typography;

const TableHeader: React.FC<ITableHeader> = ({ onSearchChange }) => {
  const dispatch = useAppDispatch();
  const addBuildingObjectModal = useAppSelector(
    buildingObjectModalsSelectors.addBuildingObjectModal
  );

  const openAddBuildingObjectModal = () => {
    dispatch(buildingObjectModalsActions.openAddBuildingObjectModal(null));
  };

  return (
    <div className='flex items-center justify-between'>
      <Title level={5}>Объекты строительства</Title>
      <div className='flex gap-4'>
        <Input
          className='w-[264px]'
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder='Название объекта'
        />
        <TourItem
          content={
            'Создание объекта строительства – это фундамент ваших расчетов. Объединяйте здания или сооружения с общим назначением или территорией под одним объектом.'
          }
          step={2}
          placement='bottomRight'
          className='w-[292px]'
          open={!addBuildingObjectModal.isRender}
        >
          <Button type='primary' onClick={openAddBuildingObjectModal}>
            Создать объект
          </Button>
        </TourItem>
      </div>
    </div>
  );
};

export const ObjectsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showSize, setShowSize] = useState<number>(defaultPageSize);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const buildingObjectLock = useAppSelector(
    buildingObjectSelectors.buildingObjectLock
  );
  const data =  useAppSelector(buildingObjectSelectors.buildingObjectItems);;
  const total = useAppSelector(buildingObjectSelectors.buildingObjectTotal);
  const addBuildingObjectModal = useAppSelector(
    buildingObjectModalsSelectors.addBuildingObjectModal
  );

  const handleRemove = (id: number) => {
    dispatch(buildingObjectActions.deleteBuildingObject({ id }));
  };

  const handleCopy = (id: number) => {
    dispatch(buildingObjectActions.copyBuildingObject({ id }));
  };

  const openAddBuildingObjectModal = () => {
    dispatch(buildingObjectModalsActions.openAddBuildingObjectModal(null));
  };

  const columns: TableColumnsType<IBuildingObject> = [
    {
      title: 'ID объекта',
      dataIndex: 'id',
    },
    {
      title: 'Название объекта и адрес',
      dataIndex: 'name',
      render: (text, record, index) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/objects/${record.id}`,
              }}
              className='block w-fit'
            >
              <Typography.Text className='text-primary block'>
                {record.name}
              </Typography.Text>
            </Link>
            <Typography.Text className='text-sm block'>
              {record.address}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: 'Количество проектов',
      dataIndex: 'calc_count',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (text) => formatToDate(text),
    },
    {
      title: '',
      dataIndex: '',
      render: (text, record) => (
        <div className='flex gap-2'>
          <Button
            icon={<CopyOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleCopy(record.id);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleRemove(record.id);
            }}
          />
        </div>
      ),
    },
  ];

  const onSearchChange = (newValue: string) => {
    setSearchValue(newValue);
  };

  useEffect(() => {
    dispatch(
      buildingObjectActions.fetchBuildingObject({
        size: showSize,
        search: searchValue,
        page: currentPage,
      })
    );
  }, [showSize, searchValue, currentPage]);

  useEffect(() => {
    return () => {
      dispatch(buildingObjectActions.setBuildingObject(null));
      dispatch(
        buildingObjectActions.setBuildingObjectLock(LoadingStatus.NEVER)
      );
    };
  }, []);

  const isDataReady =
    Boolean(searchValue !== undefined) || ( data && data.length);

  const isEmptyData =
    buildingObjectLock === LoadingStatus.LOADED &&
    !data?.length &&
    searchValue === undefined;

  const emptyPlaceholder = (
    <div className='flex text-center h-[684px] items-center justify-center'>
      <div className='max-w-[460px] flex flex-col gap-2.5 items-center'>
        <Title level={5}>Объектов сторительства пока нет</Title>
        <Text>
          Создание расчета начинается с создания объекта строительства,
          <br /> по которому осуществляется расчет. Это может быть большой ЖК,
          завод или любая другая группа строений, которая может быть объединена
          под общим названием
        </Text>
        <TourItem
          content={
            'Создание объекта строительства – это фундамент ваших расчетов. Объединяйте здания или сооружения с общим назначением или территорией под одним объектом.'
          }
          step={2}
          placement='bottom'
          className='w-[292px]'
          open={!addBuildingObjectModal.isRender}
        >
          <Button
            type='primary'
            onClick={openAddBuildingObjectModal}
            icon={<PlusOutlined />}
          >
            Создать объект
          </Button>
        </TourItem>
      </div>
    </div>
  );

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            key: 'home',
            title: 'Все расчёты',
          },
          {
            key: 'objects',
            title: 'Объекты строительства',
          },
        ]}
      />
      <ContentWrapper>
        <div className='px-6'>
          {isEmptyData && emptyPlaceholder}
          {!isEmptyData && isDataReady && (
            <Table
              columns={columns}
              dataSource={data}
              loading={buildingObjectLock === LoadingStatus.LOADING}
              title={() =>
                <TableHeader onSearchChange={onSearchChange} />
              }
              onChange={(pagination) => {
                pagination.current && setCurrentPage(pagination.current);
              }}
              rowKey='id'
              onRow={(record) => ({
                onClick: () => {
                  navigate(`/objects/${record.id}`);
                },
              })}
              pagination={{
                total: total ?? 0,
                showTotal: (total) => `${total} объектов`,
                defaultPageSize: defaultPageSize,
                defaultCurrent: 1,
                onShowSizeChange(_, size) {
                  setShowSize(size);
                },
              }}
              scroll={{ y: 'calc(100vh - 415px)' }}
            />
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};
