import { Button, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

const { Text, Title } = Typography;

interface IMainLevelDoors {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const MainLevelDoorsCompact: React.FC<IMainLevelDoors> = ({
  handleBlur,
}) => {
  return (
    <div>
      <Title level={5}>
        Параметры лифтового холла: двери на <u>основном</u> посадочном этаже.
      </Title>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
            addonBefore={<i>m =</i>}
          name='m'
          addonAfter={<i>шт</i>}
          label='Количество дверей лифтового холла на основном посадочном этаже'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              h<sub>dr</sub> =
            </i>
          }
          name='hdr'
          addonAfter={<i>м</i>}
          label='Высота двери лифтового холла'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
           addonBefore={
            <i>
              b<sub>dr</sub> =
            </i>
          }
          name='bdr'
          addonAfter={<i>м</i>}
          label='Ширина двери лифтового холла'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              mF<sub>dr</sub> =
            </i>
          }
          name='mFdr'
          disabled
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          label='Площадь двери лифтового холла на промежуточном посадочном этаже'
          placeholder='Введите значение'
          onBlur={handleBlur}
          size='large'
        />
      </div>
    </div>
  );
};
