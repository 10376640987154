import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import AuthRootRoute from '@/pages/auth/AuthRootRoute';

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/auth/*' element={<AuthRootRoute />} />
      <Route path='/' element={<Navigate to='/auth/login' />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};

export default PublicRoutes;
