import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card } from 'antd';

import { InputForm } from '@/components/form/Input';
import { useAppDispatch } from '@/core/redux/hooks';
import { usersActions } from '@/core/redux/slices/users/slice';
import User from '@/images/user.svg';

import { ProfileCard } from '../ProfileCard/ProfileCard';

interface IUserInfo {
  name: string;
  phone: string;
  city: string;
}

interface IUserInfoForm {
  name: string;
  phone: string;
  city: string;
}

export const UserInfo: React.FC<IUserInfo> = ({ name, phone, city }) => {
  const methods = useForm<IUserInfoForm>();

  const dispatch = useAppDispatch();

  const onSubmit = (data: IUserInfoForm) => {
    dispatch(
      usersActions.updateUser({
        name: data['name'],
        phone: data['phone'],
        city: data['city'],
      })
    );
  };

  return (
    <ProfileCard>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='flex gap-6 items-center'>
            <img
              src={User}
              alt='Аэропро.Эксперт'
              className='w-[72px] h-[72px]'
            />
            <div className='flex gap-2'>
              <Button disabled>Загрузить новое фото</Button>
              <Button danger disabled>
                Удалить фото
              </Button>
            </div>
          </div>
          <div className='mt-6'>
            <div className='grid grid-cols-2 gap-6'>
              <div className='col-span-2'>
                <InputForm
                  name='name'
                  label='ФИО'
                  defaultValue={name}
                  size='large'
                />
              </div>
              <InputForm
                name='city'
                label='Город'
                size='large'
                defaultValue={city}
                placeholder='Ваш город'
              />
              <InputForm
                name='phone'
                label='Номер телефона'
                defaultValue={phone}
                size='large'
              />
            </div>
          </div>
          <div className='flex justify-end mt-12'>
            <Button type='primary' htmlType='submit' size='large'>
              Сохранить изменения
            </Button>
          </div>
        </form>
      </FormProvider>
    </ProfileCard>
  );
};
