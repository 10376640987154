import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { PasswordForm } from '@/components/form/Password';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { authActions } from '@/core/redux/slices/auth/slice';
import { usersSelectors } from '@/core/redux/slices/users/selectors';

const schema = yup.object().shape({
  code: yup.string().required('Поле обязательно для заполнения'),
  password: yup.string().required('Поле обязательно для заполнения'),
  passwordConfirmation: yup
    .string()
    .required('Поле обязательно для заполнения')
    .oneOf([yup.ref('password')], 'Пароли должны совпадать'),
});

interface IResetPasswordConfirmForm {
  code: string;
  password: string;
  passwordConfirmation: string;
}

export const ResetPasswordConfirmPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(usersSelectors.user);
  const forgotPasswordConfirmLock = useAppSelector(
    authSelectors.forgotPasswordConfirmLock
  );

  const methods = useForm<IResetPasswordConfirmForm>({
    resolver: yupResolver(schema),
  });
  const { Text, Title } = Typography;

  const [isDisabled, setIsDisabled] = useState(false);
  const [counter, setCounter] = useState(60);

  const onSubmit = (data: IResetPasswordConfirmForm) => {
    if (!user) {
      return;
    }

    dispatch(
      authActions.forgotPasswordConfirm({
        code: data.code,
        password: data.password,
        email: user.email,
      })
    );
  };

  const handleNewCodeRequest = () => {
    if (!user) {
      return;
    }

    dispatch(
      authActions.forgotPassword({
        email: user.email,
      })
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    // Decrement the counter if it is greater then 0
    if (isDisabled && counter > 0) {
      timer = setTimeout(() => {
        setCounter((prev) => prev - 1); // Decrement the counter by 1 after 1000ms (1 second)
      }, 1000);
    }
    // If the counter reaches 0, reset isDisabled to false and counter to 60
    else if (counter === 0) {
      setIsDisabled(false);
      setCounter(60);
    }

    // Clean up the timeout
    return () => {
      clearTimeout(timer);
    };
  }, [isDisabled, counter]);

  useEffect(() => {
    if (forgotPasswordConfirmLock === LoadingStatus.LOADED) {
      navigate('/auth/login');
    }
  }, [forgotPasswordConfirmLock]);

  // Convert the counter value to minutes and seconds
  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;

  // Format the seconds to always show two digits
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <Card
      style={{
        width: 368,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete='off'>
          <div className='flex flex-col gap-2 mb-6'>
            <Title level={4}>Восстановление пароля</Title>
            <Text>Мы выслали код подтверждения на {user?.email}</Text>
          </div>

          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-2'>
              <InputForm
                name='code'
                placeholder='Код подтверждения'
                size='large'
                autoComplete='off'
              />
              <PasswordForm
                name='password'
                placeholder='Новый пароль'
                size='large'
              />
              <PasswordForm
                name='passwordConfirmation'
                placeholder='Повторите новый пароль'
                size='large'
              />
            </div>
            <div>
              <Button
                htmlType='submit'
                type='primary'
                block
                className='mb-2'
                loading={forgotPasswordConfirmLock === LoadingStatus.LOADING}
              >
                Обновить пароль и войти
              </Button>
              <Button
                htmlType='button'
                type='primary'
                block
                disabled={isDisabled}
                onClick={() => {
                  handleNewCodeRequest();
                  setIsDisabled(true);
                }}
              >
                Получить новый код{' '}
                {isDisabled && '(' + minutes + ':' + formattedSeconds + ')'}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
