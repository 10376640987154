import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

export interface IBuildingObject {
  id: number;
  name: string;
  address: string | null;
  comment: string | null;
  created_at: string;
  updated_at: string;
  owner_uuid: string;
  calc_count: number;
}
export interface IBuildingObjectLock {
  status: LoadingStatus;
  response: ICurrentBuildingObject | null;
}
export interface ICurrentBuildingObject {
  id: number;
  name: string;
  address: string | null;
  comment: string | null;
  created_at: string;
  updated_at: string;
  owner_uuid: string;
}

export interface IBuildingObjectResponse {
  pages?: number | null | undefined;
  page: number | null;
  size: number | null;
  items: IBuildingObject[];
  total: number | null;
  links: {
    first: string | null;
    last: string | null;
    self: string | null;
    next: string | null;
    prev: string | null;
  };
}

export interface IBuildingObjectFetchPayload {
  created_at__gte?: string;
  created_at__lte?: string;
  order_by?: string;
  search?: string;
  page?: number;
  size?: number;
}

export interface ICurrentBuildingObjectFetchPayload {
  id: number;
}

export interface IBuildingObjectAddPayload {
  name: string;
  address: string;
  comment?: string;
}

export interface IBuildingObjectUpdatePayload {
  buildingObjectID: number;
  name: string;
  address: string;
  comment?: string | null;
}

export interface IBuildingObjectDeletePayload {
  id: number;
}

export interface IBuildingObjectCopyPayload {
  id: number;
}

export interface IBuildingObjectState {
  isInitialLoad: boolean;
  currentBuildingObject: ICurrentBuildingObject | null;
  buildingObject: IBuildingObjectResponse | null;
  buildingObjectLock: LoadingStatus;
  addBuildingObjectLock: IBuildingObjectLock;
}

const initialState: IBuildingObjectState = {
  isInitialLoad: true,
  currentBuildingObject: null,
  buildingObject: null,
  buildingObjectLock: LoadingStatus.NEVER,
  addBuildingObjectLock: {
    status: LoadingStatus.NEVER,
    response: null,
  },
};

export const buildingObjectSlice = createSlice({
  name: 'buildingObject',
  initialState,
  reducers: {
    //building object list
    fetchBuildingObject: (
      state,
      action: PayloadAction<IBuildingObjectFetchPayload | undefined | null>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setBuildingObject: (
      state,
      action: PayloadAction<IBuildingObjectResponse | null>
    ) => ({
      ...state,
      buildingObject: action.payload,
    }),
    setBuildingObjectLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      buildingObjectLock: action.payload,
    }),
    setIsInitialLoad: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isInitialLoad: action.payload,
    }),
    //current building object
    fetchCurrentBuildingObject: (
      state,
      action: PayloadAction<ICurrentBuildingObjectFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setCurrentBuildingObject: (
      state,
      action: PayloadAction<ICurrentBuildingObject | null>
    ) => ({
      ...state,
      currentBuildingObject: action.payload,
    }),
    //add building object
    addBuildingObject: (
      state,
      action: PayloadAction<IBuildingObjectAddPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAddBuildingObjectLock: (
      state,
      action: PayloadAction<IBuildingObjectLock>
    ) => ({
      ...state,
      addBuildingObjectLock: action.payload,
    }),
    //update building object
    updateBuildingObject: (
      state,
      action: PayloadAction<IBuildingObjectUpdatePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    //delete building object
    deleteBuildingObject: (
      state,
      action: PayloadAction<IBuildingObjectDeletePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    //copy building object
    copyBuildingObject: (
      state,
      action: PayloadAction<IBuildingObjectCopyPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const buildingObjectReducer = buildingObjectSlice.reducer;
export const buildingObjectActions = buildingObjectSlice.actions;
