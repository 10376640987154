import { FocusEvent, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectProps, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { SelectForm } from '@/components/form/Select';
import { DebounceSelect } from '@/components/ui/DebounceSelect/DebounceSelect';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

const { Title } = Typography;

export interface IAirCurtainsView {
  systemID: number;
}

export const AirCurtainsView: React.FC<IAirCurtainsView> = ({ systemID }) => {
  const cityParams = useAppSelector(systemParamsSelectors.cityParams);
  const cityParamsLock = useAppSelector(systemParamsSelectors.cityParamsLock);
  const systemState = useAppSelector(systemSelectors.systemState);
  const dispatch = useAppDispatch();

  const methods = useForm<any>({
    defaultValues: {
      city_id: 1,
      city: 'Москва',
      pa: null,
      ta: 0,
      va: 10,
      b: null,
      delta: 0.03,
      Ga: null,
      La: null,
    },
  });

  const cityParamsOptions: SelectProps['options'] = useMemo(() => {
    if (!cityParams) {
      return [];
    }

    return cityParams.map((item) => ({
      value: item.id,
      label: item.city,
    }));
  }, [cityParams]);

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    dispatch(
      systemCalculationsActions.performAirCurtains({
        calc_id: systemID,
        data: data,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  const onSelectedCityChange = (id: string) => {
    if (!cityParams) {
      return;
    }

    const selectedCityID = Number(id);

    const selectedCity = cityParams.find((item) => item.id === selectedCityID);

    if (selectedCity) {
      methods.setValue('city', String(selectedCity.city));
      methods.setValue('ta', Number(selectedCity.cold_temp));
    }

    handlePerformCalculation();
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  useEffect(() => {
    if (!systemState) {
      dispatch(
        systemParamsActions.fetchCityParams({
          page: 1,
          size: 10,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (cityParamsLock === LoadingStatus.LOADED) {
      !systemState && cityParams && onSelectedCityChange('1');
    }
  }, [cityParams]);

  return (
    <FormProvider {...methods}>
      <form>
        <div className='py-4 px-2'>
          <Title level={5} className='mb-4'>
            Город
          </Title>
          <DebounceSelect
            size='large'
            showSearch
            placeholder='Выберите город'
            defaultValue={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            value={{
              value: methods.getValues('city_id'),
              label: methods.getValues('city'),
            }}
            style={{ width: '100%' }}
            onChange={(newValue: any) => {
              methods.setValue('city_id', newValue.key);
              onSelectedCityChange(newValue.key);
            }}
            options={cityParamsOptions}
            fetchOptions={(search: string) => {
              dispatch(
                systemParamsActions.fetchCityParams({
                  page: 1,
                  size: 10,
                  search: search,
                })
              );
            }}
            loading={cityParamsLock === LoadingStatus.LOADING}
          />
        </div>

        <div className='py-4 px-2'>
          <div className='flex flex-col gap-8'>
            <div className='grid grid-cols-2 gap-4 gap-y-8'>
              <InputNumberForm
                name='ta'
                addonBefore={
                  <i>
                    t<sub>a</sub> =
                  </i>
                }
                addonAfter={'°C'}
                label='Температура наружного воздуха'
                disabled
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                name='pa'
                addonBefore={
                  <i>
                    ρ<sub>a</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    кг/м<sup>3</sup>
                  </i>
                }
                label='Плотность наружного воздуха'
                disabled
                onBlur={handleBlur}
                size='large'
              />
            </div>

            <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                name='va'
                addonBefore={
                  <i>
                    v<sub>a</sub> =
                  </i>
                }
                addonAfter={<i>м/с</i>}
                label='Cкорость истечения воздуха из соплового аппарата'
                note='Не менее 10 м/с'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                name='b'
                addonBefore={<i>b =</i>}
                addonAfter={<i>м</i>}
                label='Длина сопла в горизонтальной проекции'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                name='delta'
                addonBefore={<i>δ =</i>}
                addonAfter={<i>м</i>}
                label='Ширина сопла в горизонтальной проекции'
                step='0.01'
                note='Не менее 0.03 м'
                onBlur={handleBlur}
                size='large'
              />
            </div>

            <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                name='Ga'
                addonBefore={
                  <i>
                    G<sub>a</sub> =
                  </i>
                }
                addonAfter={<i>кг/с</i>}
                label='Массовый расход воздуха, подаваемый в сопловой аппарат воздушной завесы'
                disabled
                onBlur={handleBlur}
                fixValue={2}
                size='large'
              />
              <InputNumberForm
                name='La'
                addonBefore={
                  <i>
                    L<sub>a</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    м<sup>3</sup>/ч
                  </i>
                }
                label='Объемный расход воздуха, подаваемый в сопловой аппарат воздушной завесы'
                disabled
                onBlur={handleBlur}
                size='large'
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
