import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import {
  ISystem,
  ISystemAddPayload,
  ISystemDeletePayload,
  ISystemFetchPayload,
  ISystemList,
  ISystemListFetchPayload,
  ISystemUpdatePayload,
  systemActions,
} from './slice';

type SystemListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/calculation/{calculation_id}/smoke_extraction_calc',
  'get',
  '200'
>;

type SystemResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_calc/{id}',
  'get',
  '200'
>;

type SystemAddRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_calc',
  'post'
>;

type SystemUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_calc/{id}',
  'put'
>;

type SystemDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_calc/{id}',
  'delete'
>;

type SystemCopyRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_calc/{id}/copy',
  'post'
>;

// System List

function* fetchSystemList(
  action: PayloadAction<ISystemListFetchPayload>
): Generator<any, void, SystemListResponse> {
  const { payload } = action;

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/calculation/{calculation_id}/smoke_extraction_calc',
      'get',
      {
        params: {
          calculation_id: payload['calculation_id'],
        },
        query: {
          created_at__gte: payload?.['created_at__gte'],
          created_at__lte: payload?.['created_at__lte'],
          order_by: payload?.['order_by'],
          page: payload?.['page'],
          size: payload?.['size'],
        },
        ...authAdd(),
      }
    );

    const systemList: ISystemList = response;

    yield put(systemActions.setSystemList(systemList));
  } catch (error) {
    console.log('Error on system fetching');
  }
}

// System
function* fetchSystem(
  action: PayloadAction<ISystemFetchPayload>
): Generator<any, void, SystemResponse> {
  const { payload } = action;

  yield put(systemActions.setSystemLock(LoadingStatus.LOADING));

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/smoke_extraction_calc/{id}',
      'get',
      {
        params: {
          id: payload.id,
        },
        ...authAdd(),
      }
    );

    const system: ISystem = response;

    yield put(systemActions.setSystem(system));
    yield put(systemActions.setIsReportReady(system.state.finished));

    yield put(systemActions.setSystemLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on system fetching');
    yield put(systemActions.setSystemLock(LoadingStatus.ERROR));

  }
}

// Add system

function* addSystem(
  action: PayloadAction<ISystemAddPayload>
): Generator<any, void, any> {
  const { calculation_id, name, type } = action.payload;

  yield put(
    systemActions.setAddSystemLock({
      status: LoadingStatus.LOADING,
      reponse: null,
    })
  );

  const request: SystemAddRequest = {
    json: {
      calculation_id: calculation_id,
      name: name,
      type: type,
    },
    ...authAdd(),
  };

  try {
    const response: ISystem = yield call(
      restCall,
      '/smoke_extraction/smoke_extraction_calc',
      'post',
      request
    );

    yield put(
      systemActions.setAddSystemLock({
        status: LoadingStatus.LOADED,
        reponse: response,
      })
    );
  } catch (error: any) {
    yield put(
      systemActions.setAddSystemLock({
        status: LoadingStatus.ERROR,
        reponse: error,
      })
    );
    console.log('Error on create system', error);
  }
}

// Update system

function* updateSystem(
  action: PayloadAction<ISystemUpdatePayload>
): Generator<any, void, any> {
  const { systemID, name } = action.payload;

  const request: SystemUpdateRequest = {
    params: {
      id: systemID,
    },
    json: {
      name: name,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/smoke_extraction_calc/{id}',
      'put',
      request
    );
  } catch (error: any) {
    console.log('Error on update system', error);
  }
}

function* deleteSystem(
  action: PayloadAction<ISystemDeletePayload>
): Generator<any, void, any> {
  const { id } = action.payload;

  yield put(systemActions.setUpdateSystemLock(LoadingStatus.LOADING));

  const request: SystemDeleteRequest = {
    params: {
      id: id,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/smoke_extraction_calc/{id}',
      'delete',
      request
    );

    yield put(systemActions.setUpdateSystemLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on delete system', error);
    yield put(systemActions.setUpdateSystemLock(LoadingStatus.ERROR));
  }
}

function* copySystem(
  action: PayloadAction<ISystemDeletePayload>
): Generator<any, void, any> {
  const { id } = action.payload;

  yield put(systemActions.setUpdateSystemLock(LoadingStatus.LOADING));

  const request: SystemCopyRequest = {
    params: {
      id: id,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/smoke_extraction_calc/{id}/copy',
      'post',
      request
    );

    yield put(systemActions.setUpdateSystemLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on delete system', error);
    yield put(systemActions.setUpdateSystemLock(LoadingStatus.ERROR));
  }
}

export const systemSagas = [
  takeLatest(systemActions.fetchSystemList, fetchSystemList),
  takeLatest(systemActions.fetchSystem, fetchSystem),
  takeLatest(systemActions.addSystem, addSystem),
  takeLatest(systemActions.updateSystem, updateSystem),
  takeLatest(systemActions.deleteSystem, deleteSystem),
  takeLatest(systemActions.copySystem, copySystem),
];
