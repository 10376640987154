import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined, DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Table, TableColumnsType, Typography } from 'antd';

import { InputForm } from '@/components/form/Input';
import { Breadcrumbs } from '@/components/ui/Breadcrumbs/Breadcrumbs';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  calculationActions,
  ICalculation,
} from '@/core/redux/slices/smokeExtraction/calculation/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import {
  ISystemItem,
  systemActions,
} from '@/core/redux/slices/smokeExtraction/system/slice';
import { formatToDate } from '@/core/utils/dateUtils';

import { useCurrentCalculation } from '../hooks/useCurrentCalculation';

const defaultPageSize = 10;

const { Title, Text } = Typography;

interface ISystemCalculationEdit {
  calculationID: number;
  currentCalculation?: ICalculation | null;
}

interface IEditCalculationForm {
  calc_name?: string | null;
  object_name: string;
  object_address: string;
}

const SystemHeader: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [objectsID] = useState(Number(params.id));
  const [calculationID] = useState(Number(params.calculationId));

  const handleAddSystem = (id: number) => {
    navigate(`/objects/${objectsID}/${id}/system`);
  };

  return (
    <div className='flex justify-between py-4 px-2'>
      <div className='flex gap-4 items-center'>
        <Button
          icon={<LeftOutlined />}
          onClick={() => navigate(`/objects/${objectsID}`)}
        ></Button>
        <Title level={5} className='!mb-0'>
          Расчёты систем
        </Title>
      </div>
      <TourItem
        step={6}
        content='Внутри проекта будут храниться все созданные расчеты. Нажмите «Создать систему», чтобы продолжить.'
        placement='bottomRight'
        className='w-[292px]'
      >
        <Button type='primary' onClick={() => handleAddSystem(calculationID)}>
          Создать систему
        </Button>
      </TourItem>
    </div>
  );
};

const SystemCalculationEdit: React.FC<ISystemCalculationEdit> = ({
  calculationID,
  currentCalculation,
}) => {
  const methods = useForm<IEditCalculationForm>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!currentCalculation) {
      return;
    }

    methods.reset({
      calc_name: currentCalculation?.calc_name,
      object_name: currentCalculation?.object_name,
      object_address: currentCalculation?.object_address,
    });
  }, [currentCalculation]);

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (!value || !type) {
        return;
      }

      dispatch(
        calculationActions.updateCalculation({
          calculationID: calculationID,
          calc_name: value.calc_name ?? '',
          object_name: value.object_name ?? '',
          object_address: value.object_address ?? '',
        })
      );
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return (
    <FormProvider {...methods}>
      <form>
        <div className='grid grid-cols-2 gap-4'>
          <div className='col-span-2'>
            <InputForm
              name='calc_name'
              label='Наименование проекта'
              size='large'
            />
          </div>
          <InputForm name='object_name' label='Название объекта' size='large' />
          <InputForm name='object_address' label='Адрес объекта' size='large' />
        </div>
      </form>
    </FormProvider>
  );
};

export const SystemPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const data = useAppSelector(systemSelectors.systemListItems);
  const total = useAppSelector(systemSelectors.systemListTotal);
  const updateSystemLock = useAppSelector(systemSelectors.updateSystemLock);

  const [showSize, setShowSize] = useState<number>(defaultPageSize);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [calculationID] = useState(Number(params.calculationId));
  const [objectsID] = useState(Number(params.id));

  const currentCalculation = useCurrentCalculation({
    calculationID: Number(params.calculationId),
  });

  const handleRemove = (id: number) => {
    dispatch(systemActions.deleteSystem({ id }));
  };
  const handleCopy = (id: number) => {
    dispatch(systemActions.copySystem({ id }));
  };

  useEffect(() => {
    dispatch(
      systemActions.fetchSystemList({
        calculation_id: calculationID,
        size: showSize,
        page: currentPage,
      })
    );
  }, [showSize, currentPage]);

  useEffect(() => {
    if (updateSystemLock === LoadingStatus.LOADED) {
      dispatch(
        systemActions.fetchSystemList({
          calculation_id: calculationID,
          size: showSize,
          page: currentPage,
        })
      );
    }
  }, [updateSystemLock]);

  const columns: TableColumnsType<ISystemItem> = [
    {
      title: 'Тип системы',
      dataIndex: 'type',
    },
    {
      title: 'Название системы',
      dataIndex: 'name',
      render: (text, record, index) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/objects/${objectsID}/${calculationID}/system/${record.id}`,
              }}
            >
              <Text className='text-primary'>{record.name}</Text>
            </Link>
          </div>
        );
      },
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (text) => formatToDate(text),
    },
    {
      title: '',
      dataIndex: '',
      render: (text, record) => (
        <div className='flex gap-2'>
          <Button
            icon={<CopyOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleCopy(record.id);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleRemove(record.id);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(systemActions.setSystemList(null));
      dispatch(systemActions.setUpdateSystemLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            key: 'home',
            title: 'Все расчёты',
          },
          {
            key: 'objects',
            title: <Link to={'/objects'}>Объекты строительства</Link>,
          },
          ...(currentCalculation
            ? [
                {
                  key: 'currentObject',
                  title: (
                    <Link to={`/objects/${objectsID}`}>
                      {currentCalculation.object_name}
                    </Link>
                  ),
                },
                {
                  key: 'system',
                  title: currentCalculation.calc_name,
                },
              ]
            : []),
        ]}
      />

      <ContentWrapper>
        <div className='px-6'>
          <div className='flex flex-col gap-4'>
            <SystemHeader />
            <SystemCalculationEdit
              calculationID={calculationID}
              currentCalculation={currentCalculation}
            />
            <Table
              columns={columns}
              dataSource={data}
              rowKey='id'
              onChange={(pagination) => {
                pagination.current && setCurrentPage(pagination.current);
              }}
              onRow={(record) => ({
                onClick: () => {
                  navigate(
                    `/objects/${objectsID}/${calculationID}/system/${record.id}`
                  );
                },
              })}
              pagination={{
                total: total ?? 0,
                showTotal: (total) => `${total} расчетов`,
                defaultPageSize: defaultPageSize,
                defaultCurrent: 1,
                onShowSizeChange(_, size) {
                  setShowSize(size);
                },
              }}
              scroll={{ y: 'calc(100vh - 415px)' }}
            />
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
