import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { useAppDispatch } from '@/core/redux/hooks';
import { authActions } from '@/core/redux/slices/auth/slice';
import { usersActions } from '@/core/redux/slices/users/slice';

import { ProfileCard } from '../ProfileCard/ProfileCard';

const { Title, Paragraph } = Typography;
interface ICompanyInfo {
  companyName?: string;
  jobTitle?: string;
  inn?: string;
  orgSite?: string;
}

const schema = yup.object().shape({
  companyName: yup.string().optional(),
  inn: yup
    .string()
    .test('len', 'Should have at least 10 characters', (val) =>
      val?.length ? val.length >= 10 : true
    ),
  jobTitle: yup.string().optional(),
  orgSite: yup.string().optional(),
});

export const CompanyInfo: React.FC<ICompanyInfo> = ({
  companyName,
  inn,
  jobTitle,
  orgSite,
}) => {
  const dispatch = useAppDispatch();

  const methods = useForm<ICompanyInfo>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ICompanyInfo) => {
    dispatch(
      usersActions.updateUser({
        company_name: data['companyName'],
        inn: data['inn']?.length ? data['inn'] : null,
        org_site: data['orgSite'],
        job_title: data['jobTitle'],
      })
    );
  };

  const onCreateNewAccount = () => {
    dispatch(authActions.logout());
  };

  return (
    <div className='flex flex-col gap-6'>
      <ProfileCard>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='grid grid-cols-2 gap-6'>
              <InputForm
                name='companyName'
                label='Название организации'
                defaultValue={companyName}
                size='large'
              />
              <InputForm
                name='inn'
                label='ИНН организации'
                size='large'
                defaultValue={inn}
              />
              <InputForm
                name='orgSite'
                label='Сайт организации'
                size='large'
                defaultValue={orgSite}
              />
              <InputForm
                name='jobTitle'
                label='Должность'
                defaultValue={jobTitle}
                size='large'
              />
            </div>
            <div className='flex justify-end mt-12'>
              <Button type='primary' size='large' htmlType='submit'>
                Сохранить изменения
              </Button>
            </div>
          </form>
        </FormProvider>
      </ProfileCard>
      <ProfileCard>
        <div className='flex flex-col gap-4'>
          <Title level={5} className='!m-0'>
            Добавить другое место работы
          </Title>
          <Paragraph>
            Личные проекты или проекты, не связанные с текущим местом работы,
            удобнее вести в отдельном пространстве. Вы можете создать
            допольнительный аккаунт, чтобы разграничить доступы.
          </Paragraph>
        </div>

        <div className='flex justify-end mt-12'>
          <Button size='large' onClick={onCreateNewAccount}>
            Создать новый аккаунт
          </Button>
        </div>
      </ProfileCard>
    </div>
  );
};
