import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IModal {
  isRender: boolean;
  payload: any;
}

interface IDisplayHatchModalPayload {
  id: number;
  name: string;
  description: string;
  manufacturer_id: number;
  image_url: string;
  manufacturer_name: string;
} 

interface IDisplayHatchModal {
  isRender: boolean;
  payload: IDisplayHatchModalPayload | null
}

export interface ISmokeHatchesModalsState {
  displayHatchTypeModal: IDisplayHatchModal;
  selectSmokeHatchModal: IModal;
}

const initialState: ISmokeHatchesModalsState = {
  displayHatchTypeModal: {
    isRender: false,
    payload: null,
  },
  selectSmokeHatchModal: {
    isRender: false,
    payload: null,
  },
};

export const smokeHatchesModalsSlice = createSlice({
  name: 'smokeHatches',
  initialState,
  reducers: {
    // Display hatch type modal
    openDiplayHatchTypeModal: (
      state,
      action: PayloadAction<IDisplayHatchModalPayload | null>
    ) => ({
      ...state,
      displayHatchTypeModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeDisplayHatchTypeModal: (state) => ({
      ...state,
      displayHatchTypeModal: {
        isRender: false,
        payload: null,
      },
    }),
    //Elevator shafts modal
    openSelectSmokeHatchModal: (
      state,
      action: PayloadAction<IModal | null>
    ) => ({
      ...state,
      selectSmokeHatchModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeSelectSmokeHatchModal: (state) => ({
      ...state,
      selectSmokeHatchModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const smokeHatchesModalsReducer = smokeHatchesModalsSlice.reducer;
export const smokeHatchesModalsActions = smokeHatchesModalsSlice.actions;
