import React, { useEffect, useState } from 'react';
import { Button, Spin, Typography } from 'antd';

import { useAppSelector } from '@/core/redux/hooks';
import { productsSelectors } from '@/core/redux/slices/products/selectors';
import { systemCalculationSelectors } from '@/core/redux/slices/smokeExtraction/system/calculations/selectors';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

import { SelectView } from './SelectView/SelectView';
import { TableView } from './TableView/TableView';

export const HatchSelection: React.FC<any> = ({
  handlePerformCalculation,
  productType,
  position,
}) => {
  const systemState = useAppSelector(systemSelectors.systemState);
  const smokeHatchesSelection = systemState?.smoke_hatches_selection;

  const selectedProduct = useAppSelector(productsSelectors.selectedProduct);
  const selectedManufacturer = useAppSelector(
    productsSelectors.selectedManufacture
  );
  const selectedHatchType = useAppSelector(
    systemCalculationSelectors.selectedHatchType
  );
  const hatchTableData = useAppSelector(
    systemCalculationSelectors.smokeHatchesTable
  );


  const isProductSelected = selectedProduct ? selectedProduct.id : false;
  const isSubmited = smokeHatchesSelection
    ? (smokeHatchesSelection.A && smokeHatchesSelection.B)
    : false;

  useEffect(() => {
    if (selectedProduct || selectedHatchType) {
      handlePerformCalculation();
    }
  }, [selectedProduct, selectedHatchType]);


  return (
    <div className='flex flex-col gap-4'>
      {isProductSelected && !hatchTableData && <Spin />}
      {!isProductSelected && (
        <SelectView productType={productType} position={position} />
      )}
      {isProductSelected && hatchTableData && (
        <TableView
          selectedManufacturer={selectedManufacturer}
          handlePerformCalculation={handlePerformCalculation}
          selectedProduct={selectedProduct}
          selectedType={selectedHatchType}
          tableData={hatchTableData}
          isSubmited={isSubmited}
        />
      )}
    </div>
  );
};
