import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectProps, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { DebounceSelect } from '@/components/ui/DebounceSelect/DebounceSelect';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

const { Title, Text } = Typography;

interface ICity {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handlePerformCalculation: () => void;
}

export const City: React.FC<ICity> = ({
  handleBlur,
  handlePerformCalculation,
}) => {
  const cityParams = useAppSelector(systemParamsSelectors.cityParams);
  const cityParamsLock = useAppSelector(systemParamsSelectors.cityParamsLock);
  const systemState = useAppSelector(systemSelectors.systemState);

  const dispatch = useAppDispatch();

  const methods = useFormContext();

  const cityParamsOptions: SelectProps['options'] = useMemo(() => {
    if (!cityParams) {
      return [];
    }

    return cityParams.map((item) => ({
      value: item.id,
      label: item.city,
    }));
  }, [cityParams]);

  const onSelectedCityChange = (id: string) => {
    if (!cityParams) {
      return;
    }

    const selectedCityID = Number(id);

    const selectedCity = cityParams.find((item) => item.id === selectedCityID);

    if (selectedCity) {
      methods.setValue('city', String(selectedCity.city));
      methods.setValue('ta', Number(selectedCity.cold_temp));
      methods.setValue('va', Number(selectedCity.cold_speed));
    }

    handlePerformCalculation();
  };

  useEffect(() => {
    if (!systemState) {
      dispatch(
        systemParamsActions.fetchCityParams({
          page: 1,
          size: 10,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (cityParamsLock === LoadingStatus.LOADED) {
      !systemState && cityParams && onSelectedCityChange('1');
    }
  }, [cityParams]);

  const currentElevatorShaftType = methods.watch('elevator_shaft_type');

  return (
    <>
      <div className='py-4 px-2'>
        <Title level={5} className='mb-4'>
          Город
        </Title>
        <DebounceSelect
          size='large'
          showSearch
          placeholder='Выберите город'
          defaultValue={{
            value: methods.getValues('city_id'),
            label: methods.getValues('city'),
          }}
          value={{
            value: methods.getValues('city_id'),
            label: methods.getValues('city'),
          }}
          style={{ width: '100%' }}
          options={cityParamsOptions}
          fetchOptions={(search: string) => {
            dispatch(
              systemParamsActions.fetchCityParams({
                page: 1,
                size: 10,
                search: search,
              })
            );
          }}
          loading={cityParamsLock === LoadingStatus.LOADING}
          onChange={(newValue: any) => {
            methods.setValue('city', newValue.label);
            methods.setValue('city_id', newValue.key);

            onSelectedCityChange(newValue.key);
          }}
        />
      </div>
      <div className='py-4 px-2'>
        <div className='grid grid-cols-3 gap-4 gap-y-8'>
          <InputNumberForm
            name='ta'
            addonBefore={
              <i>
                t<sub>a</sub> =
              </i>
            }
            addonAfter={'°C'}
            label='Температура наружного воздуха'
            disabled
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            name='pa'
            addonBefore={
              <i>
                ρ<sub>a</sub> =
              </i>
            }
            addonAfter={
              <i>
                кг/м<sup>3</sup>
              </i>
            }
            label='Плотность наружного воздуха'
            disabled
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                v<sub>a</sub> =
              </i>
            }
            addonAfter='м/с'
            name='va'
            disabled
            label='Скорость ветра'
            onBlur={handleBlur}
            size='large'
          />

          {!(
            currentElevatorShaftType === 1 || currentElevatorShaftType === 2
          ) && (
            <>
              <InputNumberForm
                addonBefore={
                  <i>
                    k<sub>aww</sub> =
                  </i>
                }
                name='kaww'
                label='Коэффициент ветрового напора для наветренного фасада'
                placeholder='Введите значение'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                addonBefore={
                  <i>
                    k<sub>aw0</sub> =
                  </i>
                }
                name='kaw0'
                label='Коэффициент ветрового напора для заветренного фасада'
                placeholder='Введите значение'
                onBlur={handleBlur}
                size='large'
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
