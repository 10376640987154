import { Button, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';

const { Text, Title } = Typography;

interface IIntermidiateLevelDoors {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;}

export const IntermidiateUndergroundLevelDoors: React.FC<
  IIntermidiateLevelDoors
> = ({ handleBlur }) => {
  return (
    <div>
      <Title level={5}>
        Двери лифтового холла на промежуточном подземном посадочном этаже
      </Title>
      <div className='flex flex-col gap-8'>
        <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={<i>mi =</i>}
            name='mipodz'
            addonAfter={<i>шт</i>}
            label='Количество дверей лифтового холла на промежуточном посадочном этаже'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                h<sub>dri</sub> =
              </i>
            }
            name='hdripodz'
            addonAfter={<i>м</i>}
            label='Высота двери лифтового холла'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                b<sub>dri</sub> =
              </i>
            }
            name='bdripodz'
            addonAfter={<i>м</i>}
            label='Ширина двери лифтового холла'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
        </div>
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={
              <i>
                mF<sub>dri</sub> =
              </i>
            }
            name='miFdripodz'
            addonAfter={
              <i>
                м<sup>2</sup>
              </i>
            }
            label='Площадь двери лифтового холла на промежуточном посадочном этаже'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                S<sub>dr</sub> =
              </i>
            }
            name='Sdrpodz'
            addonAfter={
              <i>
                м<sup>3</sup>/кг
              </i>
            }
            label='Удельное сопротивление воздухопроницанию дверей лифтовых холлов'
            placeholder='Введите значение'
            onBlur={handleBlur}
            fixValue={0}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                S<sub>lri</sub> =
              </i>
            }
            name='Slripodz'
            addonAfter={<i>1/(кг*м)</i>}
            label='Суммарная характеристика удельного сопротивления воздухопроницанию дверей лифтовой шахты и лифтового холла'
            placeholder='Введите значение'
            onBlur={handleBlur}
            fixValue={0}
            disabled
            size='large'
          />
        </div>

        <div>
          <RadioGroupForm
           label='Надземная часть лифтовой шахты расположена в центральном ядре?'
            name='is_located_in_the_central_core'
            vertical
            value={[
              {
                label: 'Да',
                value: true,
              },
              {
                label: 'Нет',
                value: false,
              },
            ]}
          />
        </div>

        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={
              <i>
                P<sub>l2</sub> =
              </i>
            }
            name='Pl'
            addonAfter={<i>Па</i>}
            label='Давление воздуха в лифтовой шахте на уровне геометрического центра дверей этажа'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <div></div>
          <InputNumberForm
            addonBefore={
              <i>
                G<sub>l1</sub> =
              </i>
            }
            name='Gl1'
            addonAfter={<i>кг/с</i>}
            label='Давление воздуха в лифтовой шахте на уровне геометрического центра дверей этажа'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={<i>∑ ΔGl =</i>}
            name='sum_delta_Gl'
            addonAfter={<i>кг/с</i>}
            label='Утечки воздуха по этажам'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            size='large'
          />
        </div>
      </div>
    </div>
  );
};
