import { Navigate, Route, Routes } from 'react-router-dom';

import HomeRoute from './HomeRoute';

const HomeRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<HomeRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default HomeRootRoute;
