import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.buildingObjectModals;

const addBuildingObjectModal = createSelector(
  [selector],
  (state) => state.addBuildingObjectModal
);

export const buildingObjectModalsSelectors = {
  addBuildingObjectModal,
};
