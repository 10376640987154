import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';

interface IDocumentationItem {
  title: string;
  desc: string;
  file: string;
}

const { Title, Text } = Typography;

export const DocumentationItem: React.FC<IDocumentationItem> = ({
  title,
  desc,
  file,
}) => {
  return (
    <div className='flex flex-col gap-2 items-start px-6 py-4 shadow-[0px_-1px_0px_0px_#F0F0F0_inset]'>
      <Title level={5} className='!mb-0'>
        {title}
      </Title>
      <Text>{desc}</Text>
      <div className='pt-2'>
        <Link
          to={`/docs/Нормативные документы_MVP_Ед_формат/${file}`}
          target='_blank'
          rel='noreferrer'
        >
          <Button>Посмотреть файл</Button>
        </Link>
      </div>
    </div>
  );
};
