import { Button, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

const { Text, Title } = Typography;

interface IMainLevelDoors {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const MainLevelDoors: React.FC<IMainLevelDoors> = ({ handleBlur }) => {
  return (
    <div>
      <Title level={5}>
        Двери лифтового холла на ОСНОВНОМ посадочном этаже
      </Title>
      <div className='flex flex-col gap-8'>
        <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={<i>m<sub>1</sub> =</i>}
            name='m'
            addonAfter={<i>шт</i>}
            label='Количество дверей лифтового холла на ОСНОВНОМ посадочном этаже'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                h<sub>dr1</sub> =
              </i>
            }
            name='hdr'
            addonAfter={<i>м</i>}
            label='Высота двери лифтового холла'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                b<sub>dr1</sub> =
              </i>
            }
            name='bdr'
            addonAfter={<i>м</i>}
            label='Ширина двери лифтового холла'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
        </div>
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={
              <i>
                m<sub>1</sub>F<sub>dr1</sub> =
              </i>
            }
            name='mFdr'
            addonAfter={
              <i>
                м<sup>2</sup>
              </i>
            }
            label='Площадь двери лифтового холла на промежуточном основном этаже'
            placeholder='Введите значение'
            disabled
            onBlur={handleBlur}
            fixValue={2}
            size='large'
          />

          <InputNumberForm
            addonBefore={
              <i>
                P<sub>l2</sub> =
              </i>
            }
            name='Pl'
            addonAfter={<i>Па</i>}
            label='Давление воздуха в лифтовой шахте на уровне геометрического центра дверей этажа'
            placeholder='Введите значение'
            onBlur={handleBlur}
            fixValue={1}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                G<sub>l1</sub> =
              </i>
            }
            name='Gl1'
            addonAfter={<i>кг/с</i>}
            disabled
            label='Расход воздуха из лифтовой шахты на уровне надземного (первого) этажа'
            placeholder='Введите значение'
            onBlur={handleBlur}
            fixValue={2}
            size='large'
          />
          <InputNumberForm
            addonBefore={<i>∑ ΔG<sub>li</sub> =</i>}
            name='sum_delta_Gl'
            addonAfter={<i>кг/с</i>}
            label='Суммарный расход воздуха, последовательно фильтрующегося через закрытые двери лифтовой шахты и лифтовых холлов'
            placeholder='Введите значение'
            disabled
            fixValue={2}
            onBlur={handleBlur}
            size='large'
          />
        </div>
      </div>
    </div>
  );
};
