import { Navigate, Route, Routes } from 'react-router-dom';

import AirExchangeRoute from './AirExchangeRoute';

const AirExchangeRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<AirExchangeRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default AirExchangeRootRoute;
