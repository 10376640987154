import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, SelectProps, Typography } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { SelectForm } from '@/components/form/Select';
import { Breadcrumbs } from '@/components/ui/Breadcrumbs/Breadcrumbs';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { SystemType } from '@/core/enums/systemType';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemParamsSelectors } from '@/core/redux/slices/smokeExtraction/system/params/selectors';
import { systemParamsActions } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import { systemActions } from '@/core/redux/slices/smokeExtraction/system/slice';

import { useCurrentCalculation } from '../hooks/useCurrentCalculation';

interface ISystemAddForm {
  name: string;
  type: SystemType;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.mixed<SystemType>().oneOf(Object.values(SystemType)).required(),
});

export const SystemAddPage: React.FC = () => {
  const systemTypes = useAppSelector(systemParamsSelectors.systemTypes);
  const routerParams = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const addSystemLock = useAppSelector(systemSelectors.addSystemLock);

  const currentCalculation = useCurrentCalculation({
    calculationID: Number(routerParams.calculationId),
  });

  const { Text } = Typography;

  const methods = useForm<ISystemAddForm>({
    resolver: yupResolver(schema),
  });

  const cityOptions: SelectProps['options'] = useMemo(() => {
    if (!systemTypes) {
      return [];
    }

    return systemTypes.map((item) => ({
      value: item,
      label: item,
    }));
  }, [systemTypes]);

  const onSubmit = (data: ISystemAddForm) => {
    dispatch(
      systemActions.addSystem({
        calculation_id: Number(routerParams.calculationId),
        name: data.name,
        type: data.type,
      })
    );
  };

  useEffect(() => {
    dispatch(systemParamsActions.fetchSystemTypes());
  }, []);

  useEffect(() => {
    const { status, reponse } = addSystemLock;

    if (status === LoadingStatus.LOADED && reponse) {
      navigate(
        `/objects/${routerParams.id}/${routerParams.calculationId}/system/${reponse.id}`
      );
    }
  }, [addSystemLock]);

  useEffect(() => {
    return () => {
      dispatch(
        systemActions.setAddSystemLock({
          status: LoadingStatus.NEVER,
          reponse: null,
        })
      );
    };
  }, []);

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            key: 'home',
            title: 'Все расчёты',
          },
          {
            key: 'objects',
            title: <Link to={'/objects'}>Объекты строительства</Link>,
          },
          ...(currentCalculation
            ? [
                {
                  key: 'currentObject',
                  title: (
                    <Link to={`/objects/${routerParams.id}`}>
                      {currentCalculation.object_name}
                    </Link>
                  ),
                },
                {
                  key: 'calculation',
                  title: (
                    <Link
                      to={`/objects/${routerParams.id}/${routerParams.calculationId}`}
                    >
                      {currentCalculation.calc_name}
                    </Link>
                  ),
                },
                {
                  key: 'system',
                  title: routerParams.id,
                },
              ]
            : []),
        ]}
      />
      <ContentWrapper>
        <div className='px-6'>
          <div className='px-2 py-4'>
            <div className='flex justify-between'>
              <Button
                icon={<LeftOutlined />}
                onClick={() =>
                  navigate(
                    `/objects/${routerParams.id}/${routerParams.calculationId}`
                  )
                }
              ></Button>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='flex justify-between items-center mt-4'>
                  <div className='flex gap-4 items-center'>
                    <TourItem
                      className='w-[300px]'
                      content='Введите название системы и выберите Тип системы. Это определит специфику вашего расчета.'
                      step={7}
                      placement='bottomLeft'
                      open={!methods.formState.isValid}
                    >
                      <div style={{ width: 215 }}>
                        <Text>Название системы</Text>
                        <InputForm name='name' />
                      </div>
                    </TourItem>

                    <div style={{ width: 400 }}>
                      <Text>Тип системы</Text>
                      <SelectForm name='type' options={cityOptions} />
                    </div>
                  </div>

                  <TourItem
                    content='Почти готово! Нажмите “Создать”'
                    step={8}
                    placement='bottomRight'
                    open={methods.formState.isValid}
                  >
                    <Button
                      type='primary'
                      htmlType='submit'
                      disabled={!methods.formState.isValid}
                    >
                      Создать
                    </Button>
                  </TourItem>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
