import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

// interfaces

export interface IManufacture {
  id: number;
  name: string;
  logo_url: string;
  description: string;
}

export interface IProduct {
  id: number;
  name: string;
  description: string;
  manufacturer_id: number;
  image_url: string;
}

// payloads

export interface IManufacturesFetchPayload {
  productType: string;
  orderBy?: string;
  page?: number;
  size?: number;
}

export interface IProductsFetchPayload {
  position: string;
  orderBy?: string;
  manufactureID: number;
  page?: number;
  size?: number;
}

// state

export interface IPrdouctsState {
  manufactures: IManufacture[];
  manufacturesLock: LoadingStatus;
  products: IProduct[];
  productsLock: LoadingStatus;
  // selected
  selectedManufacture: IManufacture | null;
  selectedProduct: IProduct | null;
}

const initialState: IPrdouctsState = {
  selectedManufacture: null,
  selectedProduct: null,
  manufactures: [],
  manufacturesLock: LoadingStatus.NEVER,
  products: [],
  productsLock: LoadingStatus.NEVER,
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // manufactures
    fetchManufactures: (
      state,
      action: PayloadAction<IManufacturesFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setManufactures: (state, action: PayloadAction<IManufacture[]>) => ({
      ...state,
      manufactures: action.payload,
    }),
    setManufacturesLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      manufacturesLock: action.payload,
    }),
    // products
    fetchProducts: (state, action: PayloadAction<IProductsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setProducts: (state, action: PayloadAction<IProduct[]>) => ({
      ...state,
      products: action.payload,
    }),
    setProductsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      productsLock: action.payload,
    }),
    // selected
    setSelectedManufacture: (
      state,
      action: PayloadAction<IManufacture | null>
    ) => ({
      ...state,
      selectedManufacture: action.payload,
    }),
    setSelectedProduct: (state, action: PayloadAction<IProduct | null>) => ({
      ...state,
      selectedProduct: action.payload,
    }),
  },
});

export const productsReducer = productsSlice.reducer;
export const productsActions = productsSlice.actions;
