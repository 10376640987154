import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.system.report;

const status = createSelector([selector], (state) => state.status);
const downloadLink = createSelector([selector], (state) => state.downloadLink);
const report = createSelector([selector], (state) => state.report);

export const reportSelectors = {
  report,
  status,
  downloadLink,
};
