import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

export interface ISystemPerfomCalculationPayload {
  calc_id: number;
  data: any;
}

export interface IHatchTableCalculationPayload {
  product_id: number;
  data: any;
  position: string;
}

export interface ICell {
  A: string;
  B: string;
  Ed: string;
  Scsh: string;
  n: string;
}
export interface IRow {
  rowName: string;
  cells: ICell[];
}

export interface ISmokeHatchesTable {
  columns: string[];
  rows: IRow[];
}

export interface ISystemCalculationsState {
  selectedHatchType: ICell | null;
  perfomCalculationLock: LoadingStatus;
  smokeHatchesTable: ISmokeHatchesTable | null;
}

const initialState: ISystemCalculationsState = {
  selectedHatchType: null,
  smokeHatchesTable: null,
  perfomCalculationLock: LoadingStatus.NEVER,
};

export const systemCalculations = createSlice({
  name: 'systemCalculations',
  initialState,
  reducers: {
    performFacadeSmokeHatches: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performRoofSmokeHatches: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performCompensatingAirSupply: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performAirCurtains: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performAirLocks: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performFireProofZones: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performSmokeFreeStairwells: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performRemovingFromBurning: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performRemovingFromAdjacentToBurning: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performElevatorShafts: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performAerodynamics: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    performCalculationOfSmokeRemovalDevices: (
      state,
      action: PayloadAction<ISystemPerfomCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setPerformCalculationsLock: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => ({
      ...state,
      perfomCalculationLock: action.payload,
    }),
    // hatches
    performHatchTableCalculation: (
      state,
      action: PayloadAction<IHatchTableCalculationPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setSmokeHatchesTable: (
      state,
      action: PayloadAction<ISmokeHatchesTable | null>
    ) => ({
      ...state,
      smokeHatchesTable: action.payload,
    }),
    // hatch type
    setSelectedHatchType: (state, action: PayloadAction<ICell | null>) => ({
      ...state,
      selectedHatchType: action.payload,
    }),
  },
});

export const systemCalculationsReducer = systemCalculations.reducer;
export const systemCalculationsActions = systemCalculations.actions;
