import { Controller, useFormContext } from 'react-hook-form';
import {
  Input,
  InputProps,
  Radio,
  RadioChangeEvent,
  RadioGroupProps,
  Typography,
} from 'antd';
import { RadioButtonProps } from 'antd/es/radio/radioButton';
import { twMerge } from 'tailwind-merge';

interface IRadioGroupForm extends RadioGroupProps {
  label?: string;
  name: string;
  defaultValue?: string;
  vertical?: boolean;
}

export const RadioGroupForm: React.FC<IRadioGroupForm> = (props) => {
  const { Text } = Typography;

  const { label, name, defaultValue, vertical = false } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <>
      <div className='flex flex-col gap-4'>
        {label && (
          <label htmlFor={name} className='text-secondary'>
            {label}
          </label>
        )}
        <Controller
          render={({ field }) => (
            <Radio.Group
              {...field}
              {...props}
              className={twMerge(
                'text-lg',
                vertical && 'flex flex-col gap-2',
                props.className
              )}
              onChange={(e: RadioChangeEvent) => {
                field.onChange(e);
                props.onChange?.(e);
              }}
            />
          )}
          control={control}
          name={name}
          defaultValue={defaultValue}
        />
      </div>

      {error && (
        <Text className='block' type='danger'>
          {String(error.message)}
        </Text>
      )}
    </>
  );
};
