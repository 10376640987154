import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';
import { SystemType } from '@/core/enums/systemType';

// interfaces
export interface ISystem {
  calculation_id: number;
  name: string;
  type: string;
  id: number;
  state: any;
  created_at: string;
  updated_at: string;
}

export interface ISystemItem {
  created_at: string;
  id: number;
  name: string;
  type: string;
  updated_at: string;
}

export interface ISystemList {
  pages?: number | null | undefined;
  page: number | null;
  size: number | null;
  items: ISystemItem[];
  total: number | null;
  links: {
    first: string | null;
    last: string | null;
    self: string | null;
    next: string | null;
    prev: string | null;
  };
}

// payloads

export interface ISystemListFetchPayload {
  calculation_id: number;
  created_at__gte?: string;
  created_at__lte?: string;
  order_by?: string;
  page?: number;
  size?: number;
}

export interface ISystemFetchPayload {
  id: number;
}

export interface ISystemAddPayload {
  calculation_id: number;
  name: string;
  type: SystemType;
}

export interface ISystemUpdatePayload {
 systemID: number;
 name: string;
}

export interface ISystemDeletePayload {
  id: number;
}

export interface ISystemCopyPayload {
  id: number;
}

// locks

export interface ISystemLock {
  status: LoadingStatus;
  reponse: ISystem | null | undefined;
}

// state

export interface ISystemState {
  systemList: ISystemList | null;
  system: ISystem | null;
  systemLock: LoadingStatus;
  isReportReady: boolean;
  addSystemLock: ISystemLock;
  updateSystemLock: LoadingStatus;
}

const initialState: ISystemState = {
  systemList: null,
  system: null,
  systemLock: LoadingStatus.NEVER,
  isReportReady: false,
  addSystemLock: {
    status: LoadingStatus.NEVER,
    reponse: null,
  },
  updateSystemLock: LoadingStatus.NEVER,
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    // fetch system list
    fetchSystemList: (
      state,
      action: PayloadAction<ISystemListFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setSystemList: (state, action: PayloadAction<ISystemList | null>) => ({
      ...state,
      systemList: action.payload,
    }),
    // fetch system
    fetchSystem: (state, action: PayloadAction<ISystemFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setSystem: (state, action: PayloadAction<ISystem | null>) => ({
      ...state,
      system: action.payload,
    }),
    setSystemLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      systemLock: action.payload,
    }),
    // add sytem
    addSystem: (state, action: PayloadAction<ISystemAddPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAddSystemLock: (state, action: PayloadAction<ISystemLock>) => ({
      ...state,
      addSystemLock: action.payload,
    }),
    // update system
    updateSystem: (state, action: PayloadAction<ISystemUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // delete sytem
    deleteSystem: (state, action: PayloadAction<ISystemDeletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // copy system
    copySystem: (state, action: PayloadAction<ISystemCopyPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateSystemLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateSystemLock: action.payload,
    }),
    // report
    setIsReportReady: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isReportReady: action.payload,
    }),
  },
});

export const systemReducer = systemSlice.reducer;
export const systemActions = systemSlice.actions;
