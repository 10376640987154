import { FocusEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';

import { RadioGroupForm } from '@/components/form/Radio';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemModalsActions } from '@/core/redux/slices/modals/smokeExtraction/system/slice';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

import { AboveGround } from './sections/AbovegroundPart/AbovegroundPart';
import { City } from './sections/City/City';
import { ElevatorShaft } from './sections/ElevatorShaft/ElevatorShaft';
import { ExitCabin } from './sections/ExitingCabin/ExitCabin';
import { Fan } from './sections/Fan/Fan';
import { FanStrapping } from './sections/FanStrapping/FanStrapping';
import { IntermidiateLevelDoors } from './sections/IntermidiateLevelDoors/IntermidiateLevelDoors';
import { IntermidiateUndergroundLevelDoors } from './sections/IntermidiateUndegroundLevelDoors/IntermidiateUndegroundLevelDoors';
import { MainLevelDoorsCompact } from './sections/MainLevelDoorsCompact/MainLevelDoorsCompact';
import { MainLevelDoors } from './sections/MainLeverlDoors/MainLevelDoors';
import { UndergroundPart } from './sections/UndergroundPart/UndergroundPart';

export interface IElevatorShaftsView {
  systemID: number;
}

export const ElevatorShaftsView: React.FC<IElevatorShaftsView> = ({
  systemID,
}) => {
  const elevatorShaftTypeOptions = [
    {
      label: `Лифтовые шахты центрального ядра надземной части зданий, с остановками лифтов на уровнях только надземных этажей и с выгороженными лифтовыми холлами на каждом этаже, кроме нижнего`,
      value: 1,
    },
    {
      label: `Лифтовые шахты центрального ядра надземной части зданий, с остановками лифтов на уровнях только надземных этажей и с выгороженными лифтовыми холлами на каждом этаже, включая основной посадочный`,
      value: 2,
    },
    {
      label:
        'Лифтовые шахты, размещенные у наружных ограждений здания и сообщающиеся только с надземными этажами, при устройстве выгороженного лифтового холла на нижнем надземном этаже',
      value: 3,
    },
    {
      label:
        'Лифтовые шахты, размещенные у наружных ограждений здания и сообщающиеся только с надземными этажами, без выгороженного лифтового холла на нижнем надземном этаже',
      value: 4,
    },
    {
      label: `Лифтовые шахты подземной части, с остановками на подземных этажах и нижнем надземном этаже, с выгороженными лифтовыми холлами на каждом этаже, включая основной посадочный (нижний надземный)`,
      value: 5,
    },
    {
      label: `Шахты лифтов, сообщающиеся с подземной и надземной частью здания`,
      value: 6,
    },
  ];
  const methods = useForm({
    defaultValues: {
      city_id: 1,
      city: 'Москва',
      ta: 0,
      Ta: 0,
      va: 0,
      pa: 0,
      elevator_shaft_type: 1,
      kaww: 0.8,
      kaw0: -0.6,
      n: null,
      hdl: null,
      bdl: null,
      nFdl: null,
      Flc: null,
      Fls: null,
      El: null,
      has_fenced_off_elevator: true,
      m: null,
      hdr: null,
      bdr: null,
      mFdr: null,
      trnadz: null,
      Trnadz: null,
      prnadz: null,
      Tlnadz: null,
      plnadz: null,
      Sdlnadz: null,
      Nnadz: null,
      h2: null,
      htenadz: null,
      minadz: null,
      hdrinadz: null,
      bdrinadz: null,
      miFdrinadz: null,
      Sdrnadz: null,
      Slrinadz: null,
      trpodz: null,
      Trpodz: null,
      prpodz: null,
      Tlpodz: null,
      plpodz: null,
      Sdlpodz: null,
      Npodz: null,
      h_n: null,
      htepodz: null,
      mipodz: null,
      hdripodz: null,
      bdripodz: null,
      miFdripodz: null,
      Sdrpodz: null,
      Slripodz: null,
      is_located_in_the_central_core: true,
      Pl: null,
      Gl1: null,
      sum_delta_Gl: null,
      Gl: null,
      result_table: [],
      Pdl: null,
      Lv: null,
      hlN: null,
      h0l: null,
      Psv: null,
    },
  });

  const systemState = useAppSelector(systemSelectors.systemState);

  const dispatch = useAppDispatch();

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    const cloneData = _.cloneDeep(data);

    dispatch(
      systemCalculationsActions.performElevatorShafts({
        calc_id: systemID,
        data: cloneData,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  const handleOpenResultTable = () => {
    const data = methods.getValues();
    const tableData = data.result_table;

    dispatch(
      systemModalsActions.openElevatorShaftsResultModal({
        resultTableData: tableData,
      })
    );
  };

  const currentElevatorShaftType = methods.watch('elevator_shaft_type');
  const isHasFencedOffElevator = methods.watch('has_fenced_off_elevator');

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  return (
    <FormProvider {...methods}>
      <div className='flex flex-col gap-8'>
        <RadioGroupForm
          options={elevatorShaftTypeOptions}
          name='elevator_shaft_type'
          onChange={handlePerformCalculation}
          vertical
        />
        <City
          handleBlur={handleBlur}
          handlePerformCalculation={handlePerformCalculation}
        />
        <ExitCabin handleBlur={handleBlur} />
        <ElevatorShaft handleBlur={handleBlur} />
        {currentElevatorShaftType === 5 && (
          <MainLevelDoorsCompact handleBlur={handleBlur} />
        )}
        {currentElevatorShaftType !== 5 && (
          <AboveGround handleBlur={handleBlur} />
        )}

        {currentElevatorShaftType === 5 && (
          <UndergroundPart handleBlur={handleBlur} />
        )}

        {currentElevatorShaftType === 5 && (
          <IntermidiateUndergroundLevelDoors handleBlur={handleBlur} />
        )}

        {currentElevatorShaftType !== 5 && (
          <IntermidiateLevelDoors
            handleBlur={handleBlur}
            handlePerformCalculation={handlePerformCalculation}
            isHide={
              currentElevatorShaftType === 2 || currentElevatorShaftType === 3
            }
            isCombined={currentElevatorShaftType === 6}
          />
        )}

        {currentElevatorShaftType === 2 || currentElevatorShaftType === 3 ? (
          <MainLevelDoors handleBlur={handleBlur} />
        ) : null}

        {currentElevatorShaftType === 6 && isHasFencedOffElevator && (
          <MainLevelDoors handleBlur={handleBlur} />
        )}

        {currentElevatorShaftType === 6 && (
          <UndergroundPart handleBlur={handleBlur} />
        )}

        {currentElevatorShaftType === 6 && (
          <IntermidiateUndergroundLevelDoors handleBlur={handleBlur} />
        )}

        <FanStrapping
          handleBlur={handleBlur}
          handleOpenResultTable={handleOpenResultTable}
        />
        <Fan handleBlur={handleBlur} />
      </div>
    </FormProvider>
  );
};
