import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fp from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { authActions } from '@/core/redux/slices/auth/slice';
import { usersSelectors } from '@/core/redux/slices/users/selectors';
import { usersActions } from '@/core/redux/slices/users/slice';

import { LoadingStatus } from '../enums/loadingStatus';
import { tourActions } from '../redux/slices/tour/slice';

const useAuth = () => {
  const dispatch = useAppDispatch();
  const localAccessToken = localStorage.getItem('accessToken');
  const accessToken = useAppSelector(authSelectors.accessToken);
  const currentUser = useAppSelector(usersSelectors.user);
  const currentUserLock = useAppSelector(usersSelectors.userLock);

  const navigate = useNavigate();

  const isInitialized = useMemo(
    () => accessToken && currentUserLock === LoadingStatus.LOADED,
    [currentUserLock, accessToken]
  );

  const isAuthenticated = useMemo(
    () => !fp.isEmpty(currentUser?.name),
    [currentUser]
  );

  useEffect(() => {
    localAccessToken && dispatch(authActions.setAccessToken(localAccessToken));
  }, []);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    dispatch(usersActions.fetchUser());
  }, [accessToken]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const isOnboarded = currentUser.is_onboarded;

    !isOnboarded && dispatch(tourActions.startTour())
  }, [currentUser]);

  return useMemo(
    () => ({
      isInitialized: localAccessToken ? isInitialized : true,
      isAuthenticated: isAuthenticated,
    }),
    [isAuthenticated, isInitialized]
  );
};

export default useAuth;
