import { useEffect } from 'react';

import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { productsSelectors } from '@/core/redux/slices/products/selectors';
import { productsActions } from '@/core/redux/slices/products/slice';
import { systemCalculationSelectors } from '@/core/redux/slices/smokeExtraction/system/calculations/selectors';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

export const useSavedTableHatch = () => {
  const dispatch = useAppDispatch();
  const systemState = useAppSelector(systemSelectors.systemState);

  const systemLock = useAppSelector(systemSelectors.systemLock);

  const selectedManufacturer = useAppSelector(
    productsSelectors.selectedManufacture
  );
  const selectedProduct = useAppSelector(productsSelectors.selectedProduct);
  const selectedHatchType = useAppSelector(
    systemCalculationSelectors.selectedHatchType
  );
  const smokeHatchesTable = useAppSelector(
    systemCalculationSelectors.smokeHatchesTable
  );

  useEffect(() => {
    if (systemLock !== LoadingStatus.LOADED) {
      return;
    }

    if (!systemState) {
      return;
    }

    if (!systemState.smoke_hatches_selection_enabled) {
      return;
    }

    const smokeHatchesSelection = systemState.smoke_hatches_selection;

    if (!smokeHatchesSelection) {
      return;
    }

    if (!selectedHatchType) {
      const savedTypeData = {
        A: smokeHatchesSelection.A,
        B: smokeHatchesSelection.B,
        Ed: smokeHatchesSelection.Ed || null,
        Scsh: smokeHatchesSelection.Scsh || null,
        n: smokeHatchesSelection.n,
      };

      dispatch(systemCalculationsActions.setSelectedHatchType(savedTypeData));
    }

    if (!selectedManufacturer) {
      const savedManufacturer = {
        id: smokeHatchesSelection.manufacturer_id,
        name: smokeHatchesSelection.manufacturer_name,
        logo_url: '',
        description: '',
      };

      dispatch(productsActions.setSelectedManufacture(savedManufacturer));
    }

    if (!selectedProduct) {
      const savedProductData = {
        id: smokeHatchesSelection.model_id,
        manufacturer_id: smokeHatchesSelection.manufacturer_id,
        name: smokeHatchesSelection.model_name,
        description: '',
        image_url: smokeHatchesSelection.image_url,
      };

      dispatch(productsActions.setSelectedProduct(savedProductData));
    }

    if (!smokeHatchesTable) {
      dispatch(
        systemCalculationsActions.setSmokeHatchesTable(
          smokeHatchesSelection.table
        )
      );
    }
  }, [systemLock]);

  useEffect(() => {
    return () => {
      dispatch(systemCalculationsActions.setSelectedHatchType(null));
      dispatch(productsActions.setSelectedManufacture(null));
      dispatch(productsActions.setSelectedProduct(null));
      dispatch(systemCalculationsActions.setSmokeHatchesTable(null));
    };
  }, []);
};
