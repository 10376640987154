export const DeleteIcon: React.FC = () => {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.625 2.37402H4.5C4.56875 2.37402 4.625 2.31777 4.625 2.24902V2.37402H9.375V2.24902C9.375 2.31777 9.43125 2.37402 9.5 2.37402H9.375V3.49902H10.5V2.24902C10.5 1.69746 10.0516 1.24902 9.5 1.24902H4.5C3.94844 1.24902 3.5 1.69746 3.5 2.24902V3.49902H4.625V2.37402ZM12.5 3.49902H1.5C1.22344 3.49902 1 3.72246 1 3.99902V4.49902C1 4.56777 1.05625 4.62402 1.125 4.62402H2.06875L2.45469 12.7959C2.47969 13.3287 2.92031 13.749 3.45313 13.749H10.5469C11.0813 13.749 11.5203 13.3303 11.5453 12.7959L11.9313 4.62402H12.875C12.9438 4.62402 13 4.56777 13 4.49902V3.99902C13 3.72246 12.7766 3.49902 12.5 3.49902ZM10.4266 12.624H3.57344L3.19531 4.62402H10.8047L10.4266 12.624Z'
        fill='black'
        fill-opacity='0.85'
      />
    </svg>
  );
};
