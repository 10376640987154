import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';

interface IRoomParametrs {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const { Text, Title } = Typography;

export const RoomParametrs: React.FC<IRoomParametrs> = ({ handleBlur }) => {
  return (
    <div>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='Ff'
          addonBefore={
            <i>
              F<sub>f</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          label='Площадь пола горящего помещения'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='hn'
          addonBefore={
            <i>
              h<sub>п</sub> =
            </i>
          }
          addonAfter={<i>м</i>}
          label='Высота горящего помещения'
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='V'
          addonBefore={<i>V =</i>}
          addonAfter={
            <i>
              м<sup>3</sup>
            </i>
          }
          label='Объем помещения'
          disabled
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='Fw'
          addonBefore={
            <i>
              F<sub>w</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          label='Суммарная площадь внутренней поверхности ограждающих строительных конструкций помещения'
          disabled
          onBlur={handleBlur}
          fixValue={1}
          size='large'
        />
      </div>
    </div>
  );
};
