import { createClient, NormalizeOAS, OASClient } from 'fets';

import { fromRawCookies } from '@/core/utils/cookies';
import openapi from '@/services/rest/base/openapi';

export type Normalized = NormalizeOAS<typeof openapi>;
export const client = createClient<Normalized>({
  endpoint: process.env.REACT_APP_API_URL,
});

export const authAdd = (customToken: string | null = null) => {
  const token = localStorage.getItem('accessToken');

  return {
    headers: {
      Authorization: `${customToken || token || ''}` as `Bearer ${string}`,
    },
  };
};

type ClientType = OASClient<Normalized>;

export const restCall = async <Path extends keyof ClientType>(
  path: Path,
  method: string,
  data?: any,
  token: string | null = null,
  rawResponse: boolean | null = false
) => {
  const response = await (client as any)[path][method]({
    ...data,
    ...authAdd(token),
  });

  switch (response.status) {
    case 204: {
      return response;
    }
    case 409: {
      const resp = await response.json();
      throw new Error(resp.detail.user_message);
    }
    case 400: {
      const resp = await response.json();
      throw new Error(resp.detail.user_message);
    }
    default:
      return rawResponse ? response : await response.json();
  }
};
