import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.calculation;

//calculation
const calculation = createSelector(
  [selector],
  (state) => state.calculation
);
//list
const calculationList = createSelector(
  [selector],
  (state) => state.calculationList
);
const calculationListItems = createSelector(
  [selector],
  (state) => state.calculationList?.items
);
const calculationListTotal = createSelector(
  [selector],
  (state) => state.calculationList?.total
);
const calculationListLock = createSelector(
  [selector],
  (state) => state.calculationListLock
);
//update
const updateCalculationLock = createSelector(
  [selector],
  (state) => state.updateCalculationLock
);
//add
const addCalculationLock = createSelector(
  [selector],
  (state) => state.addCalculationLock
);

export const calculationSelectors = {
  calculation,
  calculationList,
  calculationListLock,
  calculationListItems,
  calculationListTotal,
  updateCalculationLock,
  addCalculationLock
};
