import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import { tourActions } from '../../../tour/slice';

import {
  ICreateReportPayload,
  IDownloadReportPayload,
  IFetchReportPayload,
  IRequestReportPayload,
  reportActions,
} from './slice';

type RequestReportResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_calc/{id}/report',
  'get',
  '200'
>;

type DownloadReportResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_calc/{id}/report/download',
  'get',
  '200'
>;

type CreateReportRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_calc/{id}/report',
  'post'
>;

function* fetchReport(
  action: PayloadAction<IFetchReportPayload>
): Generator<any, void, RequestReportResponse> {
  const { payload } = action;
  const { id } = payload;

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/smoke_extraction_calc/{id}/report',
      'get',
      {
        params: {
          id,
        },
        ...authAdd(),
      }
    );

    yield put(reportActions.setReport(response));
  } catch (error) {
    console.log('Error on request report:', error);
  }
}

function* requestReport(
  action: PayloadAction<IRequestReportPayload>
): Generator<any, void, RequestReportResponse> {
  const { payload } = action;
  const { id, extension } = payload;
  let callCount = 0; // Initialize the call count
  const maxCallCount = 10;

  try {
    while (callCount < maxCallCount) {
      const response = yield call(
        restCall,
        '/smoke_extraction/smoke_extraction_calc/{id}/report',
        'get',
        {
          params: {
            id,
          },
          ...authAdd(),
        }
      );

      yield put(reportActions.setReport(response));

      if (response.status === 'DONE') {
        // End tour on success
        yield put(tourActions.stopTour());
        // Download report
        yield put(reportActions.downloadReport({ id, extension }));
        break; // Exit the loop if status is 'DONE'
      } else {
        yield delay(1000);
        callCount++; // Increment the call count
      }
    }

    if (callCount === maxCallCount) {
      throw new Error('API response status did not change after 10 calls.');
    }
  } catch (error) {
    console.log('Error on request report:', error);
    yield put(reportActions.setReportStatus(LoadingStatus.ERROR));

    notification.error({
      message: 'Ошибка генерации отчета',
      description: 'Ошибка генерации отчета. Попробуйте позже',
    });
  }
}

function* downloadReport(
  action: PayloadAction<IDownloadReportPayload>
): Generator<any, void, DownloadReportResponse> {
  const { payload } = action;

  yield put(reportActions.setReportStatus(LoadingStatus.LOADING));

  const { id, extension } = payload;

  try {
    const downloadLink = yield call(
      restCall,
      `/smoke_extraction/smoke_extraction_calc/{id}/report/download`,
      'get',
      {
        params: {
          id,
        },
        query: {
          report_extension: extension,
        },
        ...authAdd(),
      }
    );

    yield put(reportActions.setReportStatus(LoadingStatus.LOADED));

    yield put(reportActions.setDownloadLink(downloadLink));

    window.location.assign(downloadLink);
  } catch (error) {
    console.log('Error on download report');

    yield put(reportActions.setReportStatus(LoadingStatus.ERROR));
  }
}

function* createReport(
  action: PayloadAction<ICreateReportPayload>
): Generator<any, void, any> {
  const { id, extension } = action.payload;

  yield put(reportActions.setReportStatus(LoadingStatus.LOADING));

  const request: CreateReportRequest = {
    params: {
      id,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/smoke_extraction/smoke_extraction_calc/{id}/report',
      'post',
      request
    );

    yield put(reportActions.requestReport({ id, extension }));
  } catch (error) {
    console.log('Error on create report', error);
    yield put(reportActions.setReportStatus(LoadingStatus.ERROR));
  }
}

export const reportSagas = [
  takeLatest(reportActions.requestReport, requestReport),
  takeLatest(reportActions.downloadReport, downloadReport),
  takeEvery(reportActions.createReport, createReport),
  takeEvery(reportActions.fetchReport, fetchReport),
];
