import { useRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Carousel, Typography } from 'antd';

import { IProduct } from '@/core/redux/slices/products/slice';
import NotFoundImage from '@/images/samples/not-found.svg';

const { Text } = Typography;

interface IHatchTypeCard {
  id: number;
  name: string;
  description: string;
  imageURL: string;
  manufactureID: number;
  onSelect: (product: IProduct) => void;
  onDisplay: (product: IProduct) => void;
  className?: string;
}

interface IPrdouctsList {
  products: IProduct[];
  onSelectHatchType: (product: IProduct) => void;
  onDisplayHatchType: (product: IProduct) => void;
}

const HatchTypeCard: React.FC<IHatchTypeCard> = ({
  id,
  name,
  imageURL,
  description,
  onSelect,
  onDisplay,
  manufactureID,
}) => {
  const product = {
    id: id,
    name: name,
    image_url: imageURL,
    description: description,
    manufacturer_id: manufactureID,
  };

  return (
    <div className='pt-2 pb-4 px-4 bg-white m-4 border-primary-second rounded-lg overflow-hidden'>
      <div className='flex flex-col gap-2 items-start'>
        <div className='w-[197px] h-[197px] flex items-center justify-center self-center'>
          <img
            src={imageURL || NotFoundImage}
            alt={name}
            className='w-[197px] h-[140px] object-cover'
          />
        </div>
        <Text strong>{name}</Text>
        <Button
          type='primary'
          className='w-full'
          onClick={() => {
            onSelect(product);
          }}
        >
          Выбрать
        </Button>
        <Button
          type='link'
          onClick={() => onDisplay(product)}
          className='w-full'
        >
          Открыть характеристики
        </Button>
      </div>
    </div>
  );
};

export const ProductsList: React.FC<IPrdouctsList> = ({
  products,
  onSelectHatchType,
  onDisplayHatchType,
}) => {
  const carouselRef = useRef<any>(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isInProgress, setIsInProgress] = useState(false)

  const isDraggable = products.length > 5;

  // Move to the next slide
  const next = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    !isInProgress && carouselRef.current.next();
  };

  // Move to the previous slide
  const prev = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    !isInProgress && carouselRef.current.prev();
  };

  // Handler for slide change
  const handleAfterChange = (current: number) => {
    setCurrentSlide(current);
    setIsInProgress(false)
  };

  return (
    <div>
      <div className='flex gap-2 justify-end'>
        <Button
          disabled={currentSlide === 0}
          icon={<LeftOutlined />}
          onClick={prev}
        />
        <Button
          disabled={
            currentSlide >=
              carouselRef?.current?.innerSlider?.state?.lazyLoadedList
                ?.length || !isDraggable
          }
          icon={<RightOutlined />}
          onClick={next}
        />
      </div>

      <Carousel
        ref={carouselRef}
        slidesToShow={5}
        draggable={false}
        infinite={false}
        dots={false}
        arrows={false}
        swipeToSlide={false}
        afterChange={handleAfterChange}
        beforeChange={() => setIsInProgress(true)}
        
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        {products.map((product) => (
          <HatchTypeCard
            key={product.id}
            id={product.id}
            name={product.name}
            description={product.description}
            imageURL={product.image_url}
            onSelect={onSelectHatchType}
            onDisplay={onDisplayHatchType}
            manufactureID={product.manufacturer_id}
          />
        ))}
      </Carousel>
    </div>
  );
};
