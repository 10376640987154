import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MainLayout } from '@/layouts/MainLayout/MainLayout';
import AirExchangeRootRoute from '@/pages/air-exchange/AirExchangeRootRoute';
import DocumentationRootRoute from '@/pages/documentation/DocumentationRootRoute';
import HomeRootRoute from '@/pages/home/HomeRootRoute';
import ObjectsRootRoute from '@/pages/objects/ObjectsRootRoute';
import ProfileRootRoute from '@/pages/profile/ProfileRootRoute';
import ToolsRootRoute from '@/pages/tools/ToolsRootRoute';

const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path='/objects/*' element={<ObjectsRootRoute />} />
        <Route path='/profile/*' element={<ProfileRootRoute />} />
        <Route path='/tools' element={<ToolsRootRoute />} />
        <Route path='/documentation' element={<DocumentationRootRoute />} />
        <Route path='/air-exchange' element={<AirExchangeRootRoute />} />
        <Route path='/' element={<HomeRootRoute />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
