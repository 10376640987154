import { AddBuildingObjectModal } from '@/modals/modals/AddBuildingObjectModal/AddBuildingObjectModal';
import { AddCalculationModal } from '@/modals/modals/AddCalculationModal/AddCalculationModal';
import { DisplayResultModal } from '@/modals/modals/DisplayResultModal/DisplayResultModal';

import { DisplayHatchTypeModal } from './modals/DisplayHatchTypeModal/DisplayHatchTypeModal';
import { ElevatorShaftsResultModal } from './modals/ElevatorShaftsResultModal/ElevatorShaftsResultModal';
import { SelectSmokeHatchModal } from './modals/SelectSmokeHatchModal/SelectSmokeHatchModal';

export const ModalRoot: React.FC = () => {
  return (
    <>
      <AddBuildingObjectModal />
      <AddCalculationModal />
      <DisplayResultModal />
      <ElevatorShaftsResultModal />
      <DisplayHatchTypeModal />
      <SelectSmokeHatchModal />
    </>
  );
};
