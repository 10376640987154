import { Button, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { RadioGroupForm } from '@/components/form/Radio';

const { Text, Title } = Typography;

interface IIntermidiateLevelDoors {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handlePerformCalculation: () => void;
  isCombined: boolean;
  isHide: boolean;
}

export const IntermidiateLevelDoors: React.FC<IIntermidiateLevelDoors> = ({
  handleBlur,
  handlePerformCalculation,
  isCombined,
  isHide,
}) => {
  return (
    <div>
      <Title level={5}>
        Параметры лифтового холла: двери на <u>промежуточном</u> надземном посадочном
        этаже
      </Title>

      <div className='flex flex-col gap-8'>
        <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={<i>m =</i>}
            name='minadz'
            addonAfter={<i>шт</i>}
            label='Количество дверей лифтового холла на промежуточном посадочном этаже'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                h<sub>dr</sub> =
              </i>
            }
            name='hdrinadz'
            addonAfter={<i>м</i>}
            label='Высота двери лифтового холла'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                b<sub>dr</sub> =
              </i>
            }
            name='bdrinadz'
            addonAfter={<i>м</i>}
            label='Ширина двери лифтового холла'
            placeholder='Введите значение'
            onBlur={handleBlur}
            size='large'
          />
        </div>
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            addonBefore={
              <i>
                mF<sub>dr</sub> =
              </i>
            }
            name='miFdrinadz'
            addonAfter={
              <i>
                м<sup>2</sup>
              </i>
            }
            label='Площадь двери лифтового холла на промежуточном посадочном этаже'
            placeholder='Введите значение'
            onBlur={handleBlur}
            disabled
            fixValue={2}
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                S<sub>dr</sub> =
              </i>
            }
            name='Sdrnadz'
            addonAfter={
              <i>
                м<sup>3</sup>/кг
              </i>
            }
            label='Удельное сопротивление воздухопроницанию дверей лифтовых холлов'
            placeholder='Введите значение'
            onBlur={handleBlur}
            fixValue={0}
            disabled
            size='large'
          />
          <InputNumberForm
            addonBefore={
              <i>
                S<sub>lr</sub> =
              </i>
            }
            name='Slrinadz'
            addonAfter={<i>1/(кг*м)</i>}
            label='Суммарная характеристика удельного сопротивления воздухопроницанию дверей лифтовой шахты и лифтового холла'
            placeholder='Введите значение'
            onBlur={handleBlur}
            fixValue={0}
            disabled
            size='large'
          />

          {!isCombined ||
            (!isHide && (
              <>
                <InputNumberForm
                  addonBefore={
                    <i>
                      P<sub>l2</sub> =
                    </i>
                  }
                  name='Pl'
                  addonAfter={<i>Па</i>}
                  label='Давление воздуха в лифтовой шахте на уровне геометрического центра дверей этажа'
                  placeholder='Введите значение'
                  fixValue={1}
                  onBlur={handleBlur}
                  disabled
                  size='large'
                />
                <InputNumberForm
                  addonBefore={
                    <i>
                      G<sub>l1</sub> =
                    </i>
                  }
                  name='Gl1'
                  addonAfter={<i>кг/с</i>}
                  label='Расход воздуха из лифтовой шахты на уровне надземного (первого этажа)'
                  placeholder='Введите значение'
                  onBlur={handleBlur}
                  fixValue={2}
                  disabled
                  size='large'
                />
                <InputNumberForm
                  addonBefore={<i>∑ ΔGli =</i>}
                  name='sum_delta_Gl'
                  addonAfter={<i>кг/с</i>}
                  label='Суммарный расход воздуха, последовательно фильтрующегося через закрытые двери лифтовой шахты и лифтовых холлов'
                  placeholder='Введите значение'
                  onBlur={handleBlur}
                  fixValue={2}
                  disabled
                  size='large'
                />
              </>
            ))}
        </div>

        <div className='py-4 flex flex-col gap-4'>
          {!isCombined ? null : (
            <>
              <RadioGroupForm
                name='has_fenced_off_elevator'
                className='flex flex-col gap-2'
                label='Есть выгороженный лифтовый холл на уровне нижнего надземного (первого этажа)?'
                onChange={handlePerformCalculation}
                vertical
                options={[
                  {
                    label: 'Есть',
                    value: true,
                  },
                  {
                    label: 'Нет',
                    value: false,
                  },
                ]}
              />
              <RadioGroupForm
                label='Надземная часть лифтовой шахты расположена в центральном ядре?'
                className='flex flex-col gap-2'
                name='is_located_in_the_central_core'
                onChange={handlePerformCalculation}
                vertical
                options={[
                  {
                    label: 'Да',
                    value: true,
                  },
                  {
                    label: 'Нет',
                    value: false,
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
